import {
    ENROLLMENT_GET,
    ENROLLMENT_GET_SUCCESS,
    ENROLLMENT_GET_FAILURE,
} from './EnrollmentConstants'

const initialState = {
    isLoading: true,
    enrollment: {},
    error: ''
  }

export default function EnrollmentReducers(state = initialState, action) {
    switch (action.type) {
      case ENROLLMENT_GET:
        return {
          ...state,
          isLoading: true,
        }
      case ENROLLMENT_GET_FAILURE:
        return {
          ...state,
          isLoading: false,
          error: action.payload
        }
      case ENROLLMENT_GET_SUCCESS:
        return {
          ...state,
          isLoading: false,
          enrollment: action.payload,
        }
      default:
        return state
    }
  }