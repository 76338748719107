import moment from 'moment-timezone'
import React, { useState, useContext, createContext } from 'react'

export default function TimezoneProvider({ children }) {
    const [timezone] = useState(moment.tz.guess());
  
    return (
      <TimezoneContext.Provider value={timezone}>
        {children}
      </TimezoneContext.Provider>
    );
  }


export const TimezoneContext = createContext();
