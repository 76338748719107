import {
  SUBSCRIPTION_CREATE,
  SUBSCRIPTION_CREATE_FAILURE,
  SUBSCRIPTION_CREATE_SUCCESS,
} from './SubscriptionConstants'

const initialState = {
  isLoading: false,
  subscription: {},
  error: null
}

export default function SubscriptionReducers(state = initialState, action) {
  switch (action.type) {
    case SUBSCRIPTION_CREATE:
      return {
        ...state,
        isLoading: true,
      }
    case SUBSCRIPTION_CREATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      }
    case SUBSCRIPTION_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        subscription: action.payload,
      }
    default:
      return state
  }
}
