import {
  ORGANIZATION_CREATE,
  ORGANIZATION_CREATE_FAILURE,
  ORGANIZATION_CREATE_SUCCESS,
  ORGANIZATION_DELETE_PENDING_INVITE,
  ORGANIZATION_DELETE_PENDING_INVITE_FAILURE,
  ORGANIZATION_DELETE_PENDING_INVITE_SUCCESS
} from './OrganizationConstants'

const initialState = {
  isLoading: false,
  enrolledOrganization: {},
  message: '',
}

export default function OrganizationReducers(state = initialState, action) {
  switch (action.type) {
    case ORGANIZATION_DELETE_PENDING_INVITE:
    case ORGANIZATION_CREATE:
      return {
        ...state,
        isLoading: true,
      }
    case ORGANIZATION_DELETE_PENDING_INVITE_SUCCESS:
    case ORGANIZATION_DELETE_PENDING_INVITE_FAILURE:
    case ORGANIZATION_CREATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload,
      }
    case ORGANIZATION_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        enrolledOrganization: action.payload,
      }
    default:
      return state
  }
}
