import produce from 'immer'
import { ROOT_MERGE } from './constants'
import { merge } from '../../../utils/object'

export const initialState = {
  auth: {
    user: {
      username: '',
      token: '',
    },
    isLogged: false,
  },
  utakSession: { accesskey: '', sessionID: '' }, 

  isLoading: true,
  error: '',
}

const rootReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case ROOT_MERGE:
        return merge(draft, action.payload)
    }
  })

export default rootReducer
