export const GET_ORGANIZATION_SUBSCRIPTION = 'GET_ORGANIZATION_SUBSCRIPTION'
export const GET_ORGANIZATION_SUBSCRIPTION_SUCCESS = 'GET_ORGANIZATION_SUBSCRIPTION_SUCCESS'
export const GET_ORGANIZATION_SUBSCRIPTION_FAILURE = 'GET_ORGANIZATION_SUBSCRIPTION_FAILURE'
export const UPDATE_ORGANIZATION_SUBSCRIPTION = 'UPDATE_ORGANIZATION_SUBSCRIPTION'
export const UPDATE_ORGANIZATION_SUBSCRIPTION_SUCCESS = 'UPDATE_ORGANIZATION_SUBSCRIPTION_SUCCESS'
export const UPDATE_ORGANIZATION_SUBSCRIPTION_FAILURE = 'UPDATE_ORGANIZATION_SUBSCRIPTION_FAILURE'
export const CANCEL_ORGANIZATION_SUBSCRIPTION = 'CANCEL_ORGANIZATION_SUBSCRIPTION'
export const CANCEL_ORGANIZATION_SUBSCRIPTION_SUCCESS = 'CANCEL_ORGANIZATION_SUBSCRIPTION_SUCCESS'
export const CANCEL_ORGANIZATION_SUBSCRIPTION_FAILURE = 'CANCEL_ORGANIZATION_SUBSCRIPTION_FAILURE'
export const CREATE_EXISTING_ORGANIZATION_SUBSCRIPTION = 'CREATE_EXISTING_ORGANIZATION_SUBSCRIPTION'
export const CREATE_EXISTING_ORGANIZATION_SUBSCRIPTION_SUCCESS = 'CREATE_EXISTING_ORGANIZATION_SUBSCRIPTION_SUCCESS'
export const CREATE_EXISTING_ORGANIZATION_SUBSCRIPTION_FAILURE = 'CREATE_EXISTING_ORGANIZATION_SUBSCRIPTION_FAILURE'