/*
 *
 * GcpServiceAccountKeys constants
 *
 */

export const GCP_SERVICE_ACCOUNT_KEYS_MERGE =
  'app/GcpServiceAccountKeys/GCP_SERVICE_ACCOUNT_KEYS_MERGE'

export const GCP_SERVICE_ACCOUNT_KEYS_FETCH =
  'app/GcpServiceAccountKeys/GCP_SERVICE_ACCOUNT_KEYS_FETCH'
export const GCP_SERVICE_ACCOUNT_KEYS_SAVE =
  'app/GcpServiceAccountKeys/GCP_SERVICE_ACCOUNT_KEYS_SAVE'

  export const GCP_SERVICE_ACCOUNT_KEYS_DELETE =
  'app/GcpServiceAccountKeys/GCP_SERVICE_ACCOUNT_KEYS_DELETE'