/*
 *
 * Container constants
 *
 */

export const CONTAINER_MERGE = 'app/Container/CONTAINER_MERGE'

export const CONTAINER_FETCH = 'app/Container/CONTAINER_FETCH'
export const CONTAINER_DELETE = 'app/Container/CONTAINER_DELETE'
export const CONTAINER_DELETE_ITEMS = 'app/Container/CONTAINER_DELETE_ITEMS'
export const CONTAINER_SAVE = 'app/Container/CONTAINER_SAVE'
export const CONTAINER_MERGE_ADD_ITEMS = 'app/Container/CONTAINER_MERGE_ADD_ITEMS'
