import {
    GET_INVOICE_LIST,
    GET_INVOICE_LIST_SUCCESS,
    GET_INVOICE_LIST_FAILURE
} from './InvoicesContants'

const initialState = {
    isGetting: false,
    list: []
}

export default function InvoicesReducers(state=initialState, action) {
    switch(action.type) {
        case GET_INVOICE_LIST:
            return {
                ...state,
                isGetting: true
            }
        case GET_INVOICE_LIST_FAILURE:
            return {
                ...state,
                isGetting: false
            }
        case GET_INVOICE_LIST_SUCCESS:
            return {
                ...state,
                isGetting: false,
                list: action.payload
            }
        default:
            return state
    }
}