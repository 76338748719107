import {
  BUSINESS_GET_PRODUCTS_PRICES,
  BUSINESS_GET_PRODUCTS_PRICES_FAILURE,
  BUSINESS_GET_PRODUCTS_PRICES_SUCCESS,
} from './BusinessProductsConstants'

const initialState = {
  isLoading: false,
  products: [],
}

export default function BusinessProductsReducers(state = initialState, action) {
  switch (action.type) {
    case BUSINESS_GET_PRODUCTS_PRICES:
      return {
        ...state,
        isLoading: true,
      }
    case BUSINESS_GET_PRODUCTS_PRICES_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case BUSINESS_GET_PRODUCTS_PRICES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        products: action.payload,
      }
    default:
      return state
  }
}
