export const CLOUD_SENSE_CREATE_APPLICATION = 'CLOUD_SENSE_CREATE_APPLICATION'
export const CLOUD_SENSE_CREATE_CUSTOM_APPLICATION = 'CLOUD_SENSE_CREATE_CUSTOM_APPLICATION'
export const CLOUD_SENSE_REGISTER_CUSTOM_APPLICATION = 'CLOUD_SENSE_REGISTER_CUSTOM_APPLICATION'
export const CLOUD_SENSE_CREATE_APPLICATION_SUCCESS = 'CLOUD_SENSE_CREATE_APPLICATION_SUCCESS'
export const CLOUD_SENSE_CREATE_APPLICATION_FAILURE = 'CLOUD_SENSE_CREATE_APPLICATION_FAILURE'
export const CLOUD_SENSE_DELETE_APPLICATION = 'CLOUD_SENSE_DELETE_APPLICATION'
export const CLOUD_SENSE_DELETE_APPLICATION_SUCCESS = 'CLOUD_SENSE_DELETE_APPLICATION_SUCCESS'
export const CLOUD_SENSE_DELETE_APPLICATION_FAILURE = 'CLOUD_SENSE_DELETE_APPLICATION_FAILURE'
export const CLOUD_SENSE_DELETE_CUSTOM_APPLICATION = 'CLOUD_SENSE_DELETE_CUSTOM_APPLICATION'
export const CLOUD_SENSE_DEREGISTER_CUSTOM_APPLICATION = 'CLOUD_SENSE_DEREGISTER_CUSTOM_APPLICATION'
export const CLOUD_SENSE_UPDATE_CUSTOM_APPLICATION = 'CLOUD_SENSE_UPDATE_CUSTOM_APPLICATION'
export const CLOUD_SENSE_UPDATE_CUSTOM_APPLICATION_SUCCESS = 'CLOUD_SENSE_UPDATE_CUSTOM_APPLICATION_SUCCESS'
export const CLOUD_SENSE_UPDATE_CUSTOM_APPLICATION_FAILURE = 'CLOUD_SENSE_UPDATE_CUSTOM_APPLICATION_FAILURE'

//application details
export const CLOUD_SENSE_GET_DEPLOYED_APPLICATION_DETAILS = 'CLOUD_SENSE_GET_DEPLOYED_APPLICATION_DETAILS'
export const CLOUD_SENSE_GET_DEPLOYED_APPLICATION_DETAILS_SUCCESS = 'CLOUD_SENSE_GET_DEPLOYED_APPLICATION_DETAILS_SUCCESS'
export const CLOUD_SENSE_GET_DEPLOYED_APPLICATION_DETAILS_FAILURE = 'CLOUD_SENSE_GET_DEPLOYED_APPLICATION_DETAILS_FAILURE'

//deployed managed applications
export const CLOUD_SENSE_GET_APPLICATIONS = 'CLOUD_SENSE_GET_APPLICATIONS'
export const CLOUD_SENSE_GET_APPLICATIONS_SUCCESS = 'CLOUD_SENSE_GET_APPLICATIONS_SUCCESS'
export const CLOUD_SENSE_GET_APPLICATIONS_FAILURE = 'CLOUD_SENSE_GET_APPLICATIONS_FAILURE'

//deployed custom applications
export const CLOUD_SENSE_GET_DEPLOYED_CUSTOM_APPLICATIONS = 'CLOUD_SENSE_GET_DEPLOYED_CUSTOM_APPLICATIONS'
export const CLOUD_SENSE_GET_DEPLOYED_CUSTOM_APPLICATIONS_SUCCESS = 'CLOUD_SENSE_GET_DEPLOYED_CUSTOM_APPLICATIONS_SUCCESS'
export const CLOUD_SENSE_GET_DEPLOYED_CUSTOM_APPLICATIONS_FAILURE = 'CLOUD_SENSE_GET_DEPLOYED_CUSTOM_APPLICATIONS_FAILURE'

//custom applications
export const CLOUD_SENSE_GET_CUSTOM_APPLICATIONS = 'CLOUD_SENSE_GET_CUSTOM_APPLICATIONS'
export const CLOUD_SENSE_GET_CUSTOM_APPLICATIONS_SUCCESS = 'CLOUD_SENSE_GET_CUSTOM_APPLICATIONS_SUCCESS'
export const CLOUD_SENSE_GET_CUSTOM_APPLICATIONS_FAILURE = 'CLOUD_SENSE_GET_CUSTOM_APPLICATIONS_FAILURE'