
import { combineReducers } from 'redux'
import { all, fork } from "redux-saga/effects";
import IbmStorageReducers from './storage/IbmStorageReducers'
import ibmAuthReducers from './auth/ibmAuthReducers'
import ibmAuthMiddleware from './auth/ibmAuthMiddleware'
import IbmStorageMiddleware from './storage/IbmStorageMiddleware'

export const ibmReducer = combineReducers({
    storage: IbmStorageReducers,
    auth: ibmAuthReducers
})

export function* ibmSaga () {
  yield all([
    fork(ibmAuthMiddleware),
    fork(IbmStorageMiddleware)
  ]);
}