import { 
    CLOUD_SENSE_AWS_ADD_EKS_USERS, 
    CLOUD_SENSE_AWS_ADD_EKS_USERS_FAILURE, 
    CLOUD_SENSE_AWS_ADD_EKS_USERS_SUCCESS, 
    CLOUD_SENSE_AWS_GET_EKS_USERS, 
    CLOUD_SENSE_AWS_GET_EKS_USERS_FAILURE, 
    CLOUD_SENSE_AWS_GET_EKS_USERS_SUCCESS
 } from "./AwsUsersConstants"

const initialState = {
    isUpdating: false,
    isLoading: false,
    eksUsers: {}
}

export default function AwsUserReducer(state=initialState, action) {
    switch(action.type) {
        case CLOUD_SENSE_AWS_ADD_EKS_USERS:
            return {
                ...state,
                isUpdating: true,
            }
        case CLOUD_SENSE_AWS_ADD_EKS_USERS_SUCCESS:
            return {
                ...state,
                isUpdating: false,
            }
        case CLOUD_SENSE_AWS_ADD_EKS_USERS_FAILURE:
            return {
                ...state,
                isUpdating: false,
            }
        case CLOUD_SENSE_AWS_GET_EKS_USERS:
            return {
                ...state,
                isLoading: true
            }
        case CLOUD_SENSE_AWS_GET_EKS_USERS_SUCCESS:
            let updatedUsers = Object.assign({}, state.eksUsers)
            updatedUsers[action.payload.clusterId] = action.payload.users

            return {
                ...state,
                isLoading: false,
                eksUsers: updatedUsers
            }
        case CLOUD_SENSE_AWS_GET_EKS_USERS_FAILURE:
            return {
                ...state,
                isLoading: false
            }
        default:
            return state
    }
}