import {
    GET_PAYMENT_METHOD,
    GET_PAYMENT_METHOD_SUCCESS,
    GET_PAYMENT_METHOD_FAILURE,
    UPDATE_PAYMENT_METHOD,
    UPDATE_PAYMENT_METHOD_SUCCESS,
    UPDATE_PAYMENT_METHOD_FAILURE
} from './PaymentMethodContants'

const initialState = {
    isGetting: false,
    paymentMethod: {},
    isUpdating: false,
    isUpdated: null
}

export default function PaymentMethodReducers(state=initialState, action) {
    switch(action.type) {
        case GET_PAYMENT_METHOD:
            return {
                ...state,
                isGetting: true
            }
        case GET_PAYMENT_METHOD_FAILURE:
        case GET_PAYMENT_METHOD_SUCCESS:
            return {
                ...state,
                isGetting: false,
                paymentMethod: action.payload
            }
        case UPDATE_PAYMENT_METHOD:
            return {
                ...state,
                isGetting: true,
                isUpdating: true,
                isUpdated: false,
            }
        case UPDATE_PAYMENT_METHOD_FAILURE:
            return {
                ...state,
                isGetting: false,
                isUpdating: false,
                isUpdated: false,
            }
        case UPDATE_PAYMENT_METHOD_SUCCESS:
            return {
                ...state,
                isGetting: false,
                isUpdating: false,
                isUpdated: true,
                paymentMethod: action.payload
            }
        default:
            return state
    }
}