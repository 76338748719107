/*
 *
 * UserAccount constants
 *
 */

export const USER_ACCOUNT_MERGE = 'app/UserAccount/USER_ACCOUNT_MERGE'

export const USER_ACCOUNT_FETCH = 'app/UserAccount/USER_ACCOUNT_FETCH'
export const USER_ACCOUNT_SAVE = 'app/UserAccount/USER_ACCOUNT_SAVE'
