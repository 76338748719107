/*
 *
 * AccountCloudProvider constants
 *
 */

export const DEFAULT_ACTION = 'app/AccountCloudProvider/DEFAULT_ACTION'
export const ACCOUNT_CLOUD_PROVIDER_MERGE = 'app/AccountCloudProvider/ACCOUNT_CLOUD_PROVIDER_MERGE'
export const ACCOUNT_CLOUD_PROVIDER_FETCH = 'app/AccountCloudProvider/ACCOUNT_CLOUD_PROVIDER_FETCH'
export const ACCOUNT_CLOUD_PROVIDER_SAVE = 'app/AccountCloudProvider/ACCOUNT_CLOUD_PROVIDER_SAVE'
export const ACCOUNT_CLOUD_PROVIDER_IBM_SAVE = 'app/ACCOUNT_CLOUD_PROVIDER_IBM_SAVE'