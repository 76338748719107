export const GCP_CREATE_BUCKET = 'GCP_CREATE_BUCKET'
export const GCP_CREATE_BUCKET_SUCCESS = 'GCP_CREATE_BUCKET_SUCCESS'
export const GCP_CREATE_BUCKET_FAILURE = 'GCP_CREATE_BUCKET_FAILURE'
export const GCP_DELETE_BUCKET = 'GCP_DELETE_BUCKET'
export const GCP_DELETE_BUCKET_SUCCESS = 'GCP_DELETE_BUCKET_SUCCESS'
export const GCP_DELETE_BUCKET_FAILURE = 'GCP_DELETE_BUCKET_FAILURE'
export const GCP_LIST_BUCKETS = 'GCP_LIST_BUCKETS'
export const GCP_LIST_BUCKETS_SUCCESS = 'GCP_LIST_BUCKETS_SUCCESS'
export const GCP_LIST_BUCKETS_FAILURE = 'GCP_LIST_BUCKETS_FAILURE'
export const GCP_GET_BUCKET_OBJECTS = 'GCP_GET_BUCKET_OBJECTS'
export const GCP_GET_BUCKET_OBJECTS_SUCCESS = 'GCP_GET_BUCKET_OBJECTS_SUCCESS'
export const GCP_GET_BUCKET_OBJECTS_FAILURE = 'GCP_GET_BUCKET_OBJECTS_FAILURE'