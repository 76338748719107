// will be shited to api call result later
export const PermissionActions = {
  GET: 'get',
  LIST: 'list',
  CREATE: 'create',
  UPDATE: 'update',
  DELETE: 'delete',
  DISABLE: 'disable',
}

export const PermissionResource = {
  ORGANIZATION: 'organization',
  CLUSTER: 'cluster',
  CONTAINER: 'container',
  SPARK: 'spark',
  TEMPLATE: 'template',
  USER: 'user',
}

export const PermissionTypes = {
  OWNER: 'owner',
  MEMBER: 'member',
  ADMIN: 'admin',
}

// must delete
const PA = PermissionActions

// must delete
export const OrganizationPermissions = {
  owner: [PA.CREATE, PA.LIST, PA.CREATE, PA.UPDATE, PA.DELETE, PA.DELETE],
  member: [PA.GET, PA.LIST],
}
