export const CLOUD_SENSE_LOGGING_FETCH_LOKI_LABELS = "CLOUD_SENSE_LOGGING_FETCH_LOKI_LABELS"
export const CLOUD_SENSE_LOGGING_FETCH_LOKI_LABELS_SUCCESS = "CLOUD_SENSE_LOGGING_FETCH_LOKI_LABELS_SUCCESS"
export const CLOUD_SENSE_LOGGING_FETCH_LOKI_LABELS_ERROR = "CLOUD_SENSE_LOGGING_FETCH_LOKI_LABELS_ERROR"

export const CLOUD_SENSE_LOGGING_FETCH_LOKI_LOGS = "CLOUD_SENSE_LOGGING_FETCH_LOKI_LOGS"
export const CLOUD_SENSE_LOGGING_FETCH_LOKI_LOGS_SUCCESS = "CLOUD_SENSE_LOGGING_FETCH_LOKI_LOGS_SUCCESS"
export const CLOUD_SENSE_LOGGING_FETCH_LOKI_LOGS_ERROR = "CLOUD_SENSE_LOGGING_FETCH_LOKI_LOGS_ERROR"

export const CLOUD_SENSE_LOGGING_REFRESH_LOGS = "CLOUD_SENSE_LOGGING_REFRESH_LOGS"

export const CLOUD_SENSE_LOGGING_UPDATE_DATE_RANGE="CLOUD_SENSE_LOGGING_UPDATE_DATE_RANGE"
export const CLOUD_SENSE_LOGGING_UPDATE_TIMEZONE_PARAMETER = "CLOUD_SENSE_LOGGING_UPDATE_TIMEZONE_PARAMETER"