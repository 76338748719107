export const USER_CREATE = 'USER_CREATE'
export const USER_CREATE_SUCCESS =
  'USER_CREATE_SUCCESS'
export const USER_CREATE_FAILURE =
  'USER_CREATE_FAILURE'
export const USER_VALIDATE_PASSWORD = 
  'USER_VALIDATE_PASSWORD'
export const GET_USER_ACTIVITY_LOGS =
  'GET_USER_ACITIVTY_LOGS'
export const GET_USER_ACTIVITY_LOGS_SUCCESS =
  'GET_USER_ACITIVTY_LOGS_SUCCESS'
export const GET_USER_ACTIVITY_LOGS_FAILURE =
  'GET_USER_ACITIVTY_LOGS_FAILURE'
