import {
    GET_ORGANIZATION_SUBSCRIPTION,
    GET_ORGANIZATION_SUBSCRIPTION_SUCCESS,
    GET_ORGANIZATION_SUBSCRIPTION_FAILURE,
    UPDATE_ORGANIZATION_SUBSCRIPTION,
    UPDATE_ORGANIZATION_SUBSCRIPTION_FAILURE,
    UPDATE_ORGANIZATION_SUBSCRIPTION_SUCCESS,
    CANCEL_ORGANIZATION_SUBSCRIPTION,
    CANCEL_ORGANIZATION_SUBSCRIPTION_FAILURE,
    CANCEL_ORGANIZATION_SUBSCRIPTION_SUCCESS,
    CREATE_EXISTING_ORGANIZATION_SUBSCRIPTION,
    CREATE_EXISTING_ORGANIZATION_SUBSCRIPTION_SUCCESS,
    CREATE_EXISTING_ORGANIZATION_SUBSCRIPTION_FAILURE
} from './SubscriptionContants'

const initialState = {
    isGetting: false,
    subscription: {},
    isUpdating: false,
    isUpdated: null,
    isDeleting: false,
    isDeleted: null,
    isCreating: null,
    isCreated: false,
}

export default function SubscriptionReducers(state=initialState, action) {
    switch(action.type) {
        case GET_ORGANIZATION_SUBSCRIPTION:
            return {
                ...state,
                isGetting: true
            }
        case GET_ORGANIZATION_SUBSCRIPTION_FAILURE:
        case GET_ORGANIZATION_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                isGetting: false,
                subscription: action.payload
            }
        case UPDATE_ORGANIZATION_SUBSCRIPTION:
            return {
                ...state,
                isGetting: true,
                isUpdating: true,
                isUpdated: false,
            }
        case UPDATE_ORGANIZATION_SUBSCRIPTION_FAILURE:
            return {
                ...state,
                isGetting: false,
                isUpdating: false,
                isUpdated: false,
            }
        case UPDATE_ORGANIZATION_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                isGetting: false,
                isUpdating: false,
                isUpdated: true,
            }
        
        case CREATE_EXISTING_ORGANIZATION_SUBSCRIPTION:
            return {
                ...state,
                isCreating: true,
                isCreated: false,
            }
        case CREATE_EXISTING_ORGANIZATION_SUBSCRIPTION_FAILURE:
            return {
                ...state,
                isCreating: false,
                isCreated: false,
            }
        case CREATE_EXISTING_ORGANIZATION_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                isCreating: false,
                isCreated: true,
            }
        case CANCEL_ORGANIZATION_SUBSCRIPTION:
            return {
                ...state,
                isGetting: true,
                isDeleting: true,
                isDeleted: false,
            }
        case CANCEL_ORGANIZATION_SUBSCRIPTION_FAILURE:
            return {
                ...state,
                isGetting: false,
                isDeleting: false,
                isDeleted: false,
            }
        case CANCEL_ORGANIZATION_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                isGetting: false,
                isDeleting: false,
                isDeleted: true,
            }
        default:
            return state
    }
}