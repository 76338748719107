import { fetchJSON } from 'refresh-fetch'
import api from '../constant/api'
import history from './history'
import { auth, auth_login } from '../constant/route'
import moment from 'moment'
import { alertSnack } from '../components/AlertSnack'
import { isEmpty } from 'lodash'

const retrieveToken = () => localStorage.getItem('token')
const saveToken = (token, organization_data) => {
  if (!token) {
    return
  }
  let userData = getUser()
  let currentSelectedOrg = userData?.selectedOrganization?.organization_id
  userData.user.mgtApiToken = token
  userData.user.token = token
  userData.user.organization_data = organization_data
  userData.selectedOrganization = organization_data.filter(
    org => org.organization_id === currentSelectedOrg,
  )[0]
  localStorage.setItem('ulap_1.01 Auth', JSON.stringify(userData))
}
const clearToken = () => {
  localStorage.removeItem('ulap_1.01 Auth')
}

const retrieveRefreshToken = () => {
  return getUser().user.refresh_token
}

const login = (url, params) => {
  return fetchJSON(url, {
    method: 'POST',
    body: JSON.stringify(params),
  }).then(response => {
    return response.body
  })
}

const refreshToken = async () => {
  const token = retrieveRefreshToken()
  const response = await fetch(api.refreshTokenV2, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  const responseText = await response.json()
  return await saveToken(
    responseText.access_token,
    responseText.organization_data,
  )
}

async function refreshableFetch(url, init, force) {
  return fetch(url, init)
}

const remainingTime = accessToken => {
  const exp = JSON.parse(atob(accessToken.split('.')[1])).exp
  const now = moment()
  const rem = moment.unix(exp).diff(now, 'seconds')
  
  return rem
}

const isTokenExpiring = () => {
  const userData = getUser()
  
  try {
    if (isEmpty(userData) === false && userData.user.mgtApiToken) {
      const accessToken = userData.user.mgtApiToken
      const rem = remainingTime(accessToken)

      return rem < 60
    } else {
      history.push(auth_login)
      clearToken()
      throw new Error('Access token missing. Please login')
    }
    } catch(error) {
      clearToken()
      
      alertSnack({
        duration: 3000,
        variant: 'error',
        message: error.message
      })
  }
}

const getUser = () => {
  try {
    return JSON.parse(localStorage.getItem('ulap_1.01 Auth'))
  } catch(error) {
    return {}
  }
}

export {
  refreshableFetch,
  login,
  clearToken,
  refreshToken,
  isTokenExpiring,
  getUser,
  remainingTime
}
