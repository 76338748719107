import { createSelector } from 'reselect'
import _get from 'lodash.get'
import { initialState } from './reducer'
import { OrganizationPermissions } from '../../constant/permission'

const selectAuth = state => state.auth || initialState
const selectUser = state => state.user || initialState
const selectAuthLogged = state => _get(state, 'auth.isLogged', false)
const selectAuthUserOrganizationData = state =>
  _get(state, 'auth.userOrganizations', [])
const selectAuthSelectedOrganization = state =>
  _get(state, 'auth.selectedOrganization', null)

const makeSelectIsLogged = () =>
  createSelector(
    selectAuth,
    rootState => _get(rootState, 'isLogged', false),
  )

const makeSelectIsAdminView = () =>
  createSelector(
    selectAuth,
    rootState => _get(rootState, 'adminView', false),
  )

const makeSelectIsSuperAdmin = () =>
  createSelector(
    selectAuth,
    rootState => _get(rootState, 'user.isAdmin', false),
  )

const makeSelectAuth = () =>
  createSelector(
    selectAuth,
    rootState => rootState,
  )

const makeSelectAuthUser = () =>
  createSelector(
    selectAuth,
    rootState => rootState.user || {},
  )

const makeSelectAuthUserToken = () =>
  createSelector(
    selectAuth,
    rootState => _get(rootState, 'user.token', ''),
  )

// const makeSelectAuthUserMgtToken = () =>
//   createSelector(
//     selectAuth,
//     rootState => _get(rootState, 'user.mgtApiToken', ''),
//   )

const makeSelectAuthUserMgtToken = () => {
  const userData = localStorage.getItem('ulap_1.01 Auth')
  if (!userData) {
    return
  }
  const userDataString = JSON.parse(userData)
  return userDataString.user.token
}

const makeSelectAuthSelectedOrg = () =>
  createSelector(
    selectAuth,
    rootState => _get(rootState, 'selectedOrganization', ''),
  )

const makeSelectAuthUserOrganizations = () =>
  createSelector(
    selectAuthUserOrganizationData,
    s => s,
  )

const makeSelectAuthSelectedOrganizationId = () =>
  createSelector(
    selectAuthSelectedOrganization,
    selectAuthUserOrganizationData,
    (selected, orglist) => {
      if (selected) {
        return _get(selected, 'organization_id', 0)
      }
      return _get(orglist, '[0].organization_id', 0)
    },
  )

const meSelectAuthSelectedOrganizationRole = () =>
  createSelector(
    selectAuthSelectedOrganization,
    selectAuthUserOrganizationData,
    (selected, orglist) => {
      if (selected) {
        return _get(selected, 'role', '')
      }
      return _get(orglist, '[0].organization_id', '')
    },
  )

const makeSelectAuthUserOrganizationsActionValidator = () =>
  createSelector(
    selectAuthSelectedOrganization,
    selectedOrg => {
      if (selectedOrg) {
        return action => {
          const permission = OrganizationPermissions[selectedOrg.role]
          if (permission) {
            return permission.includes(action)
          }
          return false
        }
      }
      return () => false
    },
  )

// may will be shifted to permission container
const selectPermissions = s => _get(s, 'auth.permissions')

const makeSelectPermissions = () =>
  createSelector(
    selectPermissions,
    permissions => permissions || null,
  )

const makeSelectResourceValidator = () =>
  createSelector(
    selectAuthSelectedOrganization,
    selectPermissions,
    (selectedOrg, permissions) => {
      if (selectedOrg) {
        if (selectedOrg.role === 'admin' || selectedOrg.role === 'owner') return () => true
        return (resource, action) => {
          return !!(
            permissions &&
            permissions[resource] &&
            permissions[resource][selectedOrg.role] &&
            permissions[resource][selectedOrg.role].includes(action))
        }
      }
      return () => false
    },
  )

const makeSelectPersona = () =>
  createSelector(
    selectUser,
    rootState => _get(rootState, 'persona', ''),
  )

export {
  makeSelectAuth,
  makeSelectAuthUser,
  makeSelectAuthUserToken,
  makeSelectAuthUserMgtToken,
  makeSelectAuthSelectedOrg,
  makeSelectAuthUserOrganizations,
  makeSelectAuthSelectedOrganizationId,
  meSelectAuthSelectedOrganizationRole,
  makeSelectAuthUserOrganizationsActionValidator,
  makeSelectPermissions,
  makeSelectResourceValidator,
  makeSelectIsLogged,
  makeSelectIsSuperAdmin,
  makeSelectIsAdminView,
  makeSelectPersona,
}
