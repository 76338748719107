/*
 *
 * AccountCloudProviderAzure reducer
 *
 */
import produce from 'immer'
import { merge } from '../../utils/object'
import { ACCOUNT_CLOUD_PROVIDER_AZURE_MERGE } from './constants'

export const initialState = {
  records: null,
  isLoading: true,
  isUpdated: false,
  error: '',
  message: '', // status messaging
}

/* eslint-disable default-case, no-param-reassign */
const accountCloudProviderAzureReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case ACCOUNT_CLOUD_PROVIDER_AZURE_MERGE:
        return merge(draft, action.payload)
    }
  })

export default accountCloudProviderAzureReducer
