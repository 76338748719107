/*
 *
 * AccountCloudProviderAzure constants
 *
 */

export const ACCOUNT_CLOUD_PROVIDER_AZURE_MERGE =
  'app/AccountCloudProviderAzure/ACCOUNT_CLOUD_PROVIDER_AZURE_MERGE'

export const ACCOUNT_CLOUD_PROVIDER_AZURE_FETCH =
  'app/AccountCloudProviderAzure/ACCOUNT_CLOUD_PROVIDER_AZURE_FETCH'
export const ACCOUNT_CLOUD_PROVIDER_AZURE_SAVE =
  'app/AccountCloudProviderAzure/ACCOUNT_CLOUD_PROVIDER_AZURE_SAVE'

  export const ACCOUNT_CLOUD_PROVIDER_AZURE_DELETE =
  'app/AccountCloudProviderAzure/ACCOUNT_CLOUD_PROVIDER_AZURE_DELETE'