import api from '../../../../constant/api'
import { refreshableFetch } from '../../../../utils/fetch'

export const getIbmBuckets = async (token, organizationId) => {

    return await refreshableFetch(`${api.organization}/${organizationId}/gcp/storage`, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Access-Control-Allow-Origin': '*'
        }
    })
}

export const createIbmBucket = async (bucketName, token, organizationId) => {

    return await refreshableFetch(`${api.organization}/${organizationId}/gcp/storage`, {
        method: 'POST',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify({
            bucketName
        })
    })
}

export const deleteIbmBucket = async (bucketName, token, organizationId) => {

    return await refreshableFetch(`${api.organization}/${organizationId}/gcp/storage`, {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify({
            bucketName
        })
    })
}

export const getIbmBucketObjects = async (bucketName, token, organizationId) => {

    return await refreshableFetch(`${api.organization}/${organizationId}/gcp/storage/${bucketName}`, {
        method: 'GET',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Access-Control-Allow-Origin': '*'
        }
    })
}