/*
 *
 * NewUserHome constants
 *
 */

export const NEW_USER_HOME_MERGE = 'app/NewUserHome/NEW_USER_HOME_MERGE'

export const NEW_USER_HOME_FETCH = 'app/NewUserHome/NEW_USER_HOME_FETCH'
export const NEW_USER_HOME_SAVE = 'app/NewUserHome/NEW_USER_HOME_SAVE'
