/*
 *
 * AccountGeneral reducer
 *
 */
import produce from 'immer'
import { merge } from '../../utils/object'
import { ACCOUNT_GENERAL_MERGE, ACCOUNT_GENERAL_AVATAR_URL } from './constants'

export const initialState = {
  records: null,
  isLoading: true,
  isUpdated: false,
  error: '',
}

/* eslint-disable default-case, no-param-reassign */
const accountGeneralReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case ACCOUNT_GENERAL_MERGE:
        return merge(draft, action.payload)
      case ACCOUNT_GENERAL_AVATAR_URL:
        draft.records.avatar_url = action.payload.avatar_url
        return draft
    }
  })

export default accountGeneralReducer
