import Axios from 'axios'

export const MainRequest = Axios.create()
export const UlapRequest = Axios.create()

export const runApiInit = async AuthDB => {
  console.log('runApiInit')
  // set default header for api services
  try {
    MainRequest.defaults.headers.Authorization = 'Token ' + AuthDB.user.token
  } catch (e) {}
}