/*
 *
 * VersionControlAccounts reducer
 *
 */
import produce from 'immer'
import { merge } from '../../utils/object'
import { VERSION_CONTROL_ACCOUNTS_MERGE, VERSION_CONTROL_ACCOUNTS_FETCH } from './constants'

export const initialState = {
  records: [],
  isLoading: true,
  isUpdated: false,
  error: '',
}

/* eslint-disable default-case, no-param-reassign */
const versionControlAccountsReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case VERSION_CONTROL_ACCOUNTS_FETCH:
      case VERSION_CONTROL_ACCOUNTS_MERGE:
        return merge(draft, action.payload)
    }
  })

export default versionControlAccountsReducer
