import {
    IBM_CREATE_BUCKET,
    IBM_CREATE_BUCKET_FAILURE,
    IBM_CREATE_BUCKET_SUCCESS,
    IBM_DELETE_BUCKET,
    IBM_DELETE_BUCKET_FAILURE,
    IBM_DELETE_BUCKET_SUCCESS,
    IBM_GET_BUCKETS,
    IBM_GET_BUCKETS_FAILURE,
    IBM_GET_BUCKETS_SUCCESS
} from './IbmStorageConstants'

export function ibmCreateBucket (payload) {
    return {
        type: IBM_CREATE_BUCKET,
        payload
    }
}

export function ibmCreateBucketFailure () {
    return {
        type: IBM_CREATE_BUCKET_FAILURE
    }
}

export function ibmCreateBucketSuccess (payload) {
    return {
        type: IBM_CREATE_BUCKET_SUCCESS,
        payload
    }
}

export function ibmDeleteBucket (payload) {
    return {
        type: IBM_DELETE_BUCKET,
        payload
    }
}

export function ibmDeleteBucketFailure () {
    return {
        type: IBM_DELETE_BUCKET_FAILURE
    }
}

export function ibmDeleteBucketSuccess (payload) {
    return {
        type: IBM_DELETE_BUCKET_SUCCESS,
        payload
    }
}

export function ibmGetBuckets () {
    return {
        type: IBM_GET_BUCKETS
    }
}

export function ibmGetBucketsFailure () {
    return {
        type: IBM_GET_BUCKETS_FAILURE
    }
}

export function ibmGetBucketsSuccess (payload) {
    return {
        type: IBM_GET_BUCKETS_SUCCESS,
        payload
    }
}

export function ibmGetBucketObjects (payload) {
    return {
        type: IBM_GET_BUCKET_OBJECTS,
        payload
    }
}

export function ibmGetBucketObjectsFailure () {
    return {
        type: IBM_GET_BUCKET_OBJECTS_FAILURE
    }
}

export function ibmGetBucketObjectsSuccess (payload) {
    return {
        type: IBM_GET_BUCKET_OBJECTS_SUCCESS,
        payload
    }
}