export const CLOUD_SENSE_GET_CLUSTERS = 'CLOUD_SENSE_GET_CLUSTERS'
export const CLOUD_SENSE_GET_CLUSTERS_SUCCESS = 'CLOUD_SENSE_GET_CLUSTERS_SUCCESS'
export const CLOUD_SENSE_GET_CLUSTERS_FAILURE = 'CLOUD_SENSE_GET_CLUSTERS_FAILURE'

export const DEFAULT_ACTION = 'app/Cluster/DEFAULT_ACTION'
export const CLUSTER_MERGE = 'app/cluster/CLUSTER_MERGE'
export const CLUSTER_CREATE = 'app/cluster/CLUSTER_CREATE'
export const CLUSTER_FETCH_LIST = 'app/cluster/CLUSTER_FETCH_LIST'
export const CLUSTER_FETCH_ITEM = 'app/cluster/CLUSTER_FETCH_ITEM'
export const CLUSTER_TEMPLATE_FETCH_LIST =
  'app/cluster/CLUSTER_TEMPLATE_FETCH_LIST'
export const CLUSTER_DELETE = 'app/cluster/CLUSTER_DELETE'
export const CLUSTER_DELETE_MULTI = 'app/cluster/CLUSTER_DELETE_MULTI'
export const KUBECONFIG_DOWNLOAD = 'app/cluster/KUBECONFIG_DOWNLOAD'
export const CLUSTER_DETACH = 'app/cluster/CLUSTER_DETACH'