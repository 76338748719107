import {
    IBM_SAVE_CREDENTIALS,
    IBM_SAVE_CREDENTIALS_SUCCESS,
    IBM_SAVE_CREDENTIALS_FAILURE,
    IBM_DELETE_CREDENTIALS,
    IBM_DELETE_CREDENTIALS_SUCCESS,
    IBM_DELETE_CREDENTIALS_FAILURE,
    IBM_GET_CREDENTIALS_SUCCESS,
    IBM_GET_CREDENTIALS,
    IBM_GET_CREDENTIALS_FAILURE
} from './ibmAuthConstants'

export function ibmSaveCredentials (payload) {
    return {
        type: IBM_SAVE_CREDENTIALS,
        payload
    }
}

export function ibmSaveCredentialsSuccess (payload) {
    return {
        type: IBM_SAVE_CREDENTIALS_SUCCESS,
        payload
    }
}

export function ibmSaveCredentialsFailure () {
    return {
        type: IBM_SAVE_CREDENTIALS_FAILURE
    }
}

export function ibmDeleteCredentials () {
    return {
        type: IBM_DELETE_CREDENTIALS
    }
}

export function ibmDeleteCredentialsSuccess () {
    return {
        type: IBM_DELETE_CREDENTIALS_SUCCESS
    }
}

export function ibmDeleteCredentialsFailure () {
    return {
        type: IBM_DELETE_CREDENTIALS_FAILURE
    }
}

export function ibmGetCredentials () {
    return {
        type: IBM_GET_CREDENTIALS
    }
}

export function ibmGetCredentialsSuccess (payload) {
    return {
        type: IBM_GET_CREDENTIALS_SUCCESS,
        payload
    }
}

export function ibmGetCredentialsFailure () {
    return {
        type: IBM_GET_CREDENTIALS_FAILURE
    }
}