import React from 'react'
import SnackBar from './snackbar'

let alertSnack = snack => {
}

class AlertSnack extends React.PureComponent {
  state = {
    last: 0,
    snacklist: [],
  }

  closeSnackbar = key => {
    this.setState({
      snacklist: [...this.state.snacklist.filter(item => item.key !== key)],
    })
  }

  addSnackbar = item => {
    const currKey = this.state.last + 1

    this.setState({
      last: currKey,
      snacklist: [
        ...this.state.snacklist,
        {
          key: currKey,
          ...item,
        },
      ],
    })
  }

  onClose = key => (event, reason) => {
    if (reason === 'clickaway') {
      return
    }
    this.closeSnackbar(key)
  }
  componentDidMount() {
    alertSnack = this.addSnackbar
  }
  render() {
    return (
      <React.Fragment>
        {this.state.snacklist.map(item => (
          <SnackBar {...item} onClose={this.onClose(item.key)} open={true} />
        ))}
      </React.Fragment>
    )
  }
}

export default AlertSnack
export { alertSnack }
