export const AWS_CREATE_BUCKET = 'AWS_CREATE_BUCKET'
export const AWS_CREATE_BUCKET_SUCCESS = 'AWS_CREATE_BUCKET_SUCCESS'
export const AWS_CREATE_BUCKET_FAILURE = 'AWS_CREATE_BUCKET_FAILURE'
export const AWS_DELETE_BUCKET = 'AWS_DELETE_BUCKET'
export const AWS_DELETE_BUCKET_SUCCESS = 'AWS_DELETE_BUCKET_SUCCESS'
export const AWS_DELETE_BUCKET_FAILURE = 'AWS_DELETE_BUCKET_FAILURE'
export const AWS_LIST_BUCKETS = 'AWS_LIST_BUCKETS'
export const AWS_LIST_BUCKETS_SUCCESS = 'AWS_LIST_BUCKETS_SUCCESS'
export const AWS_LIST_BUCKETS_FAILURE = 'AWS_LIST_BUCKETS_FAILURE'

export const AWS_CREATE_WORKSPACE_BUCKET = 'AWS_CREATE_WORKSPACE_BUCKET'
export const AWS_CREATE_WORKSPACE_BUCKET_SUCCESS =
  'AWS_CREATE_WORKSPACE_BUCKET_SUCCESS'
export const AWS_CREATE_WORKSPACE_BUCKET_FAILURE =
  'AWS_CREATE_WORKSPACE_BUCKET_FAILURE'
export const AWS_LIST_WORKSPACE_BUCKETS = 'AWS_LIST_WORKSPACE_BUCKETS'
export const AWS_LIST_WORKSPACE_BUCKETS_SUCCESS =
  'AWS_LIST_WORKSPACE_BUCKETS_SUCCESS'
export const AWS_LIST_WORKSPACE_BUCKETS_FAILURE =
  'AWS_LIST_WORKSPACE_BUCKETS_FAILURE'
export const AWS_DELETE_WORKSPACE_BUCKET = 'AWS_DELETE_WORKSPACE_BUCKET'
export const AWS_DELETE_WORKSPACE_BUCKET_SUCCESS =
  'AWS_DELETE_WORKSPACE_BUCKET_SUCCESS'
export const AWS_DELETE_WORKSPACE_BUCKET_FAILURE =
  'AWS_DELETE_WORKSPACE_BUCKET_FAILURE'
export const AWS_CLEAR_WORKSPACE_BUCKETS = 'AWS_CLEAR_WORKSPACE_BUCKETS'
