/*
 *
 * App constants
 *
 */

export const DEFAULT_ACTION = 'app/App/DEFAULT_ACTION'
export const APP_MERGE = 'app/App/APP_MERGE'
export const APP_BOOTSTART = 'app/App/APP_BOOTSTART'
export const APP_CLEAN = 'app/App/APP_CLEAN'
