import React from 'react'
import { withStyles } from '@material-ui/styles';
import { CloudGroupImage } from '../../asset/image'

const styles = theme => ({
  wrapper: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '8%',
  },

  contentWrapper: {
    position: 'relative',
  },

  content: {
    '& > div:first-child': {
      position: 'relative',
      textAlign: 'center',
    },
  },
  errorCode: {
    position: 'absolute',
    zIndex: 1,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    '& > p:first-child': {
      color: theme.palette.whites.white32,
      fontSize: 50,
      margin: 0,
      fontWeight: 800,
    },
    '& > p': {
      color: theme.palette.whites.white32,
      fontSize: 24,
      margin: 0,
      fontWeight: 400,
    },
  },
});
class ErrorBoundary extends React.Component {
    constructor(props) {
      super(props);
      this.state = { hasError: false };
    }
  
    static getDerivedStateFromError(error) {
      return { hasError: true };
    }
  
    render() {
      const { classes } = this.props;
      if (this.state.hasError) {
        return (
          <div className={classes.wrapper}>
            <div className={classes.contentWrapper}>
              <div className={classes.content}>
                <div>
                  <img src={CloudGroupImage} />
                  <div className={classes.errorCode}>
                    <p>Whoops, something went wrong...</p>
                    <p>Unexpected Error</p>
                    <p>There was an unexpected error. Please reload the page and try again.</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }
  
      return this.props.children; 
    }
}

export default withStyles(styles)(ErrorBoundary);