import produce from 'immer'
import moment from 'moment'
import {
  CLOUD_SENSE_LOGGING_FETCH_LOKI_LABELS,
  CLOUD_SENSE_LOGGING_FETCH_LOKI_LABELS_ERROR,
  CLOUD_SENSE_LOGGING_FETCH_LOKI_LABELS_SUCCESS,
  CLOUD_SENSE_LOGGING_FETCH_LOKI_LOGS,
  CLOUD_SENSE_LOGGING_FETCH_LOKI_LOGS_ERROR,
  CLOUD_SENSE_LOGGING_FETCH_LOKI_LOGS_SUCCESS,
  CLOUD_SENSE_LOGGING_UPDATE_DATE_RANGE,
  CLOUD_SENSE_LOGGING_UPDATE_TIMEZONE_PARAMETER,
} from './CloudSenseLoggingConstants'

const ALL_NAMESPACES_QUERY = '.+'
const ALL_CONTAINERS_QUERY = '.*'
const ALL_PODS_QUERY = '.*'

/**
 * @dev At the moment the UI does not support changing the limit, namespace, container, or pod.
 * @dev Do not update these variables
 */
export const initialState = {
  isFetchingLogs: false,
  isFetchingLabels: false,
  isLogsError: false,
  isLabelsError: false,
  logError: '',
  labelsError: '',
  lastUpdate: moment().valueOf(),
  loggingStateParameters: {
    timezone: 'UTC',
    //last parameters dispatched to fetch labels/logs (parameters)
    labels: {
      cluster_id: 0,
      created_at: moment(),
      startRange:
        moment()
          .subtract(1, 'hours')
          .valueOf() * 1000000,
      endRange: moment().valueOf() * 1000000,
    },
    logs: {
      cluster_id: 0,
      startRange:
        moment().subtract(1, 'hours')
          .valueOf() * 1000000,
      endRange: moment().valueOf() * 1000000,
      query: '',
      limit: 10,
      namespace: ALL_NAMESPACES_QUERY,
      container: ALL_CONTAINERS_QUERY,
      pod: ALL_PODS_QUERY,
      step: 1800,
      interval: '30m',
    },
  },
  loggingState: {
    //current logging state based on most recent fetched results
    labels: {
      namespace: [],
      container: [],
      pod: [],
    },
    logs: { logs: [], stats: { summary: {}, querier: {}, ingester: {} } },
    chartData: [],
  },
}

const cloudSenseLoggingReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case CLOUD_SENSE_LOGGING_UPDATE_TIMEZONE_PARAMETER:
        return {
          ...state,
          loggingStateParameters: {
            ...state.loggingStateParameters,
            timezone: action.payload
          }
        }
      case CLOUD_SENSE_LOGGING_FETCH_LOKI_LOGS_SUCCESS:
        const { logs, chartData } = action.payload
        return {
          ...state,
          isFetchingLogs: false,
          loggingState: {
            labels: { ...state.loggingState.labels },
            logs,
            chartData,
          },
        }
      case CLOUD_SENSE_LOGGING_FETCH_LOKI_LOGS_ERROR:
        return {
          ...state,
          isFetchingLogs: false,
          isLogsError: true,
          logError: action.payload,
          loggingState: {
            labels: { ...state.loggingState.labels },
            logs: { logs: [], stats: { summary: {}, querier: {}, ingester: {} } },
            chartData: [],
          },
        }
      case CLOUD_SENSE_LOGGING_FETCH_LOKI_LOGS:
        const {
          params: { clusterId },
        } = action.payload
        return {
          ...state,
          isFetchingLogs: true,
          loggingStateParameters: {
            ...state.loggingStateParameters,
            labels: {
              ...state.loggingStateParameters.labels,
            },
            logs: {
              ...state.loggingStateParameters.logs,
              cluster_id: clusterId,
            },
          },
        }
      /**
       * @dev lokiLabels has the following shape: { namespace: [], container: [], pod: [] }
       */
      case CLOUD_SENSE_LOGGING_FETCH_LOKI_LABELS_SUCCESS:
        const {
          created_at = moment(),
          cluster_id = 0,
          namespace = [],
          container = [],
          pod = [],
        } = action.payload
        return {
          ...state,
          isFetchingLabels: false,
          loggingState: {
            ...state.loggingState,
            labels: { cluster_id, created_at, namespace, container, pod },
          },
        }
      case CLOUD_SENSE_LOGGING_FETCH_LOKI_LABELS_ERROR:
        return {
          ...state,
          isFetchingLabels: false,
          isLabelsError: true,
          labelsError: action.payload,
          loggingState: {
            ...state.loggingState,
            labels: { namespace: [], container: [], pod: [] },
          },
        }
      case CLOUD_SENSE_LOGGING_FETCH_LOKI_LABELS:
        return {
          ...state,
          isFetchingLabels: true,
          loggingStateParameters: {
            ...state.loggingStateParameters,
            logs: {
              ...state.loggingStateParameters.logs,
            },
            labels: {
              ...state.loggingStateParameters.labels,
              cluster_id: action.payload.params.clusterId,
              created_at: action.payload.params.creationTime,
            },
          },
        }
     case CLOUD_SENSE_LOGGING_UPDATE_DATE_RANGE:
        const { startRange, endRange } = action.payload

        return {
            ...state,
            loggingStateParameters: {
              ...state.loggingStateParameters,
                labels: {
                    ...state.loggingStateParameters.labels,
                    startRange,
                    endRange
                },
                logs: {
                    ...state.loggingStateParameters.logs,
                    startRange,
                    endRange
                }
            }
        }
      default:
        return state
    }
  })

export default cloudSenseLoggingReducer
