/*
 *
 * AccountContainerRegistry constants
 *
 */

export const DEFAULT_ACTION = 'app/AccountContainerRegistry/DEFAULT_ACTION'

export const ACCOUNT_CONTAINER_REGISTRY_MERGE =
  'app/AccountContainerRegistry/ACCOUNT_CONTAINER_REGISTRY_MERGE'
export const ACCOUNT_CONTAINER_REGISTRY_FETCH =
  'app/AccountContainerRegistry/ACCOUNT_CONTAINER_REGISTRY_FETCH'
export const ACCOUNT_CONTAINER_REGISTRY_SAVE =
  'app/AccountContainerRegistry/ACCOUNT_CONTAINER_REGISTRY_SAVE'
