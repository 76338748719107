/*
 *
 * App reducer
 *
 */
import produce from 'immer'
import { DEFAULT_ACTION, APP_MERGE } from './constants'
import { merge } from '../../utils/object'

export const initialState = {
  isLoading: true,
}

/* eslint-disable default-case, no-param-reassign */
const appReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case APP_MERGE:
        return merge(draft, action.payload)
      case DEFAULT_ACTION:
        break
    }
  })

export default appReducer
