import { createMuiTheme } from '@material-ui/core/styles'

export const projectTheme = createMuiTheme({
  typography: {
    fontFamily: [
      'Roboto',
      'Arial',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Raleway',
      '"Helvetica Neue"',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    fontSize: 14,
    htmlFontSize: 14,
    body1: {
      color: 'rgba(255, 255, 255, 0.56)',
      fontSize: '14px',
    },
    body2: {
      fontSize: '14px',
      color: '#FFF'
    }
  },
  palette: {
    type: 'dark',
    primary: {
      main: '#34A4EC',
      pictionBlue: '#34A4EC',
      curiousBlue: '#2C85C9',
      stTopaz: '#215492',
    },
    secondary: {
      main: '#D84555',
      angerRed: '#FF4838',
      sun: '#FFB010',
      mountainMeadow: '#20B878',
      ozmoBlue: '#0090FF',
      jacarandaPink: '#BA55F8',
    },
    whites: {
      white100: '#FFFFFF',
      white80: 'rgba(255, 255, 255, 0.8)',
      white6: 'rgba(255, 255, 255, 0.06)',
      white56: 'rgba(255, 255, 255, 0.56)',
      white32: 'rgba(255, 255, 255, 0.32)',
      white16: 'rgba(255, 255, 255, 0.16)',
      white8: ' rgba(255, 255, 255, 0.08)',
      white40: ' rgba(255, 255, 255, 0.4)',
      white88: ' rgba(255, 255, 255, 0.88)',
      white24: ' rgba(255, 255, 255, 0.24)',
    },
    black: {
      totalEclipse: '#2D333D',
      skyCaptain: '#252A32',
      cinderGrey: '#23272F',
      washedBlack: '#20242B',
      appBar: '#2A2F38',
      cinderBlack: '#212429',
      black: '#000000',
      newGray: '#1D1F24'
    },
    background: {
      toolbar: '#2A2F38',
      paper: '#252A32',
      default: '#FFFFFF',
    },
    persona: {
      card: '#25282E',
      topbar: '#212429',
      content: '#1D1F24'
    },
    text: {
      primary: '#FFF',
      secondary: 'rgba(255, 255, 255, 0.56)',
      highlight: '#FFF'
    }
  },
  sizes: {
    main: {
      xs: '0.75rem',
      sm: '0.875rem',
      md: '1rem',
      l: '1.5625rem',
      xl: '2rem',
    },
  },
  overrides: {
    MuiAlert: {
      root: {
        borderRadius: 8,
        height: 56,
        paddingTop: 20,
        paddingBottom: 20,
        paddingLeft: 26,
        paddingRight: 21,
        fontSize: 16,
        fontFamily: 'Roboto',
        fontWeight: 400,
        letterSpacing: 0.4,
      },
      icon: {
        padding: 0,
      },
      message: {
        padding: 0,
      },
      standardError: {
        color: '#FF4838',
        backgroundColor: 'rgba(255, 72, 56, 0.24)',
      },
      standardInfo: {
        color: '#34A4EC',
        backgroundColor: 'rgba(52, 164, 236, 0.24)',
      },
    },
    MuiError: {
      color: '#FF4838',
    },
    MuiPaper: {
      //background: '#252A32', //TODO: Refactor "doesn't exist"
      rounded: {
        borderRadius: 8,
      },
    },
    MuiButton: {
      root: {
        fontSize: '14px',
      },
    },
    MuiOutlinedInput: {
      input: {
        '&:-webkit-autofill': {
          transitionDelay: '9999s',
          transitionProperty: 'background-color, color',
          '-webkit-box-shadow': 'none !important',
        },
      },
    },
  },
})
