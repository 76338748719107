/**
 * app.js
 *
 * This is the entry file for the application, only setup and boilerplate
 * code.
 */

// Needed for redux-saga es6 generator support
import '@babel/polyfill'

// Import all the third party stuff
import React, { lazy, Suspense, useEffect } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { ConnectedRouter } from 'connected-react-router'

import CssBaseline from '@material-ui/core/CssBaseline'
import history from 'utils/history'
import 'sanitize.css/sanitize.css'

const App = lazy(() => import('./containers/App'))

import { getWithExpiry, setWithExpiry } from './containers/ErrorBoundary/localStorage'

import { ThemeProvider } from '@material-ui/core/styles'
// Import Language Provider
import LanguageProvider from './containers/LanguageProvider'

import GlobalStyle from './global-styles'
import { runStorageInit } from './service/storage'
import AppLoading from './components/AppLoading'

import { ErrorBoundary } from 'react-error-boundary'

// Load the favicon and the .htaccess file
/* eslint-disable import/no-unresolved, import/extensions */
import '!file-loader?name=[name].[ext]!./images/favicon.ico'
import 'file-loader?name=.htaccess!./.htaccess'
/* eslint-enable import/no-unresolved, import/extensions */

import configureStore from './configureStore'

// Import i18n messages
import { translationMessages } from './i18n'
import { AlertSnack } from './components'
import { projectTheme } from './material_theme'
import TimezoneProvider from './hooks/useTimezone/context'

// Create redux store with history
const initialState = {}
const store = configureStore(initialState, history)
const MOUNT_NODE = document.getElementById('app')

const ErrorFallback = ({ error }) => {
  useEffect(() => {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (error?.message && chunkFailedMessage.test(error.message)) {
      if (!getWithExpiry("chunk_failed")) {
        setWithExpiry("chunk_failed", "true", 10000);
        window.location.reload();
        throw new Error('Test')
      }
    }
  }, [error])

  return (
    <div>
      <p>Something went wrong.</p>
      <pre>{error?.message}</pre>
    </div>
  );
}

const render = async messages => {
  await runStorageInit()

  ReactDOM.render(
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Suspense fallback={<AppLoading />}>
        <ThemeProvider theme={projectTheme}>
          <TimezoneProvider>
          <Provider store={store}>
            <LanguageProvider messages={messages}>
              <ConnectedRouter history={history}>
                <CssBaseline />
                <AlertSnack />
                <GlobalStyle />
                  <App />
              </ConnectedRouter>
            </LanguageProvider>
          </Provider>
          </TimezoneProvider>
        </ThemeProvider>
      </Suspense>
    </ErrorBoundary>,
    MOUNT_NODE,
  )
}

if (module.hot) {
  // Hot reloadable React components and translation json files
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./i18n', 'containers/App'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE)
    render(translationMessages)
  })
}

// Chunked polyfill for browsers without Intl support
if (!window.Intl) {
  new Promise(resolve => {
    resolve(import('intl'))
  })
    .then(() => Promise.all([import('intl/locale-data/jsonp/en.js')]))
    .then(() => render(translationMessages))
    .catch(err => {
      throw err
    })
} else {
  render(translationMessages)
}
