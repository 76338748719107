import {
    AWS_LIST_CLUSTER_STACK_DETAILS,
    AWS_LIST_CLUSTER_STACK_DETAILS_SUCCESS,
    AWS_LIST_CLUSTER_STACK_DETAILS_FAILURE
  } from './AwsClusterConstants'

const initialState = {
    clusters: {},
    isUpdatingStackDetails: false,
    isListingStackDetails: false
}

export default function AwsClusterReducers(state=initialState, action) {
    switch(action.type) {
        case AWS_LIST_CLUSTER_STACK_DETAILS:
            return {
                ...state,
                isUpdatingStackDetails: true
            }
        case AWS_LIST_CLUSTER_STACK_DETAILS_SUCCESS:
            let updatedClusterStackDetails = Object.assign({}, state.clusters)
            if (!updatedClusterStackDetails[action.payload.clusterId]) updatedClusterStackDetails[action.payload.clusterId] = {}
            updatedClusterStackDetails[action.payload.clusterId]['stackDetails'] = action.payload.stackDetails

            return {
                ...state,
                isUpdatingStackDetails: false,
                clusters: updatedClusterStackDetails
            }
        case AWS_LIST_CLUSTER_STACK_DETAILS_FAILURE:
            return {
                ...state,
                isUpdatingStackDetails: false,
            }
        default:
            return state
    }
}