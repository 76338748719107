import {
  CLOUD_SENSE_CREATE_WORKSPACE,
  CLOUD_SENSE_CREATE_WORKSPACE_SUCCESS,
  CLOUD_SENSE_CREATE_WORKSPACE_FAILURE,
  CLOUD_SENSE_GET_WORKSPACE,
  CLOUD_SENSE_GET_WORKSPACE_SUCCESS,
  CLOUD_SENSE_GET_WORKSPACE_FAILURE,
  CLOUD_SENSE_GET_WORKSPACE_USERS,
  CLOUD_SENSE_GET_WORKSPACE_USERS_SUCCESS,
  CLOUD_SENSE_GET_WORKSPACE_USERS_FAILURE,
  CLOUD_SENSE_ADD_WORKSPACE_USERS,
  CLOUD_SENSE_ADD_WORKSPACE_USERS_SUCCESS,
  CLOUD_SENSE_ADD_WORKSPACE_USERS_FAILURE,
  IMPORT_CLUSTER_WORKSPACE,
  IMPORT_CLUSTER_WORKSPACE_SUCCESS,
  IMPORT_CLUSTER_WORKSPACE_FAILURE,
  CLOUD_SENSE_REMOVE_USER_FROM_WORKSPACE,
  CLOUD_SENSE_SWITCH_WORKSPACE,
} from './CloudSenseWorkspaceConstants'

const initialState = {
  isCreating: false,
  isGetting: false,
  isUsersGetting: false,
  isDeleteFailed: {},
  isDeletedSuccess: {},
  users: [],
  isImportingCluster: false,
  importClusterError: null,
  importClusterSuccess: false,
  workspace_selected: -1
}

export default function CloudSenseWorkspaceReducers(state = initialState, action) {
  switch (action.type) {
    case CLOUD_SENSE_CREATE_WORKSPACE:
    case CLOUD_SENSE_ADD_WORKSPACE_USERS: {
      return {
        ...state,
        isCreating: true,
      }
    }
    case CLOUD_SENSE_ADD_WORKSPACE_USERS_SUCCESS: 
    case CLOUD_SENSE_CREATE_WORKSPACE_SUCCESS:
      return {
        ...state,
        isCreating: false,
        ...action.payload,
      }
    case CLOUD_SENSE_CREATE_WORKSPACE_FAILURE:
      return {
        ...state,
        isCreating: false,
      }
    case CLOUD_SENSE_GET_WORKSPACE:
      return {
        ...state,
        isGetting: true,
      }
    case CLOUD_SENSE_GET_WORKSPACE_SUCCESS:
      return {
        ...state,
        isGetting: false,
        ...action.payload,
      }
    case CLOUD_SENSE_GET_WORKSPACE_FAILURE:
      return {
        ...state,
        isGetting: false,
      }
    case CLOUD_SENSE_GET_WORKSPACE_USERS: {
      return {
        ...state,
        isUsersGetting: true,
      }
    }
    case CLOUD_SENSE_GET_WORKSPACE_USERS_SUCCESS: {
      return {
        ...state,
        isUsersGetting: false,
        users: action.payload,
      }
    }
    case CLOUD_SENSE_GET_WORKSPACE_USERS_FAILURE:
    case CLOUD_SENSE_ADD_WORKSPACE_USERS_FAILURE: {
      return {
        ...state,
        isUserGetting: false,
        ...action.payload,
      }
    }
    case IMPORT_CLUSTER_WORKSPACE: {
      return {
        isImportingCluster: true,
      }
    }
    case IMPORT_CLUSTER_WORKSPACE_SUCCESS: {
      return {
        isImportingCluster: false,
        importClusterSuccess: action.payload,
      }
    }
    case IMPORT_CLUSTER_WORKSPACE_FAILURE: {
      return {
        isUserGetting: false,
        importClusterError: action.payload,
      }
    }
    case CLOUD_SENSE_REMOVE_USER_FROM_WORKSPACE: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case CLOUD_SENSE_SWITCH_WORKSPACE: {
      return {
        ...state,
        workspace_selected: action.payload
      }
    }
    default:
      return state
  }
}
