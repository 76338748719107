import { 
    CLOUD_SENSE_LIST_REPOSITORIES, 
    CLOUD_SENSE_LIST_REPOSITORIES_SUCCESS, 
    CLOUD_SENSE_LIST_REPOSITORIES_FAILURE, 
    CLOUD_SENSE_UPDATE_REPOSITORY, 
    CLOUD_SENSE_DELETE_REPOSITORY 
} from './CloudSenseRepositoryConstants'

const initialState = {
    isGetting: false,
    repositories: []
}

export default function CloudSenseRepositoryReducers(state=initialState, action) {
    switch(action.type) {
        case CLOUD_SENSE_LIST_REPOSITORIES:
            return {
                ...state,
                isGetting: true,                
            }
        case CLOUD_SENSE_LIST_REPOSITORIES_SUCCESS:
            return {
                ...state,
                isGetting: false,
                repositories: action.payload
            }
        case CLOUD_SENSE_LIST_REPOSITORIES_FAILURE:
            return {
                ...state,
                isGetting: false, 
            }
        case CLOUD_SENSE_UPDATE_REPOSITORY:
        case CLOUD_SENSE_DELETE_REPOSITORY:
            return state
        default:
            return state
    }
}