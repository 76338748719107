// The following components make up the Azure Blob Service:

//     The storage account itself
//     A container within the storage account
//     A blob within a container

export const AZURE_CREATE_CONTAINER = 'AZURE_CREATE_CONTAINER'
export const AZURE_CREATE_CONTAINER_SUCCESS = 'AZURE_CREATE_CONTAINER_SUCCESS'
export const AZURE_CREATE_CONTAINER_FAILURE = 'AZURE_CREATE_CONTAINER_FAILURE'
export const AZURE_DELETE_CONTAINER = 'AZURE_DELETE_CONTAINER'
export const AZURE_DELETE_CONTAINER_SUCCESS = 'AZURE_DELETE_CONTAINER_SUCCESS'
export const AZURE_DELETE_CONTAINER_FAILURE = 'AZURE_DELETE_CONTAINER_FAILURE'
export const AZURE_GET_CONTAINERS = 'AZURE_GET_CONTAINERS'
export const AZURE_GET_CONTAINERS_SUCCESS = 'AZURE_GET_CONTAINERS_SUCCESS'
export const AZURE_GET_CONTAINERS_FAILURE = 'AZURE_GET_CONTAINERS_FAILURE'
export const AZURE_GET_CONTAINER_BLOBS = 'AZURE_GET_CONTAINER_BLOBS'
export const AZURE_GET_CONTAINER_BLOBS_SUCCESS = 'AZURE_GET_CONTAINER_BLOBS_SUCCESS'
export const AZURE_GET_CONTAINER_BLOBS_FAILURE = 'AZURE_GET_CONTAINER_BLOBS_FAILURE'