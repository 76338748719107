import produce from 'immer'
import { merge } from '../../../../utils/object'

import {
    CLOUD_SENSE_GET_CLUSTERS,
    CLOUD_SENSE_GET_CLUSTERS_FAILURE,
    CLOUD_SENSE_GET_CLUSTERS_SUCCESS,
    DEFAULT_ACTION,
    CLUSTER_MERGE
} from './CloudSenseClusterConstants'

const initialState = {
    isCreating: false,
    isGetting: false,
    isLoading: false,
    isLoadingCreate: false,
    error: '',
    message: '',
    kubernetes: [],
    meta: {},
    sparkStatusList: {},
    isUpdated: false,
    details: null,
    overview: {},
}

/* eslint-disable default-case, no-param-reassign */

const cloudSenseClusterReducer = (state = initialState, action) => 
    produce(state, draft => {
        switch(action.type) {
            case CLOUD_SENSE_GET_CLUSTERS:
                return {
                    ...state,
                    isGetting: true
                }
            case CLOUD_SENSE_GET_CLUSTERS_FAILURE:
                return {
                    ...state,
                    isGetting: false
                }
            case CLOUD_SENSE_GET_CLUSTERS_SUCCESS:
                return {
                    ...state,
                    isGetting: false,
                    ...action.payload
                }
            case CLUSTER_MERGE:
                return merge(draft, action.payload)
            case DEFAULT_ACTION:
                break
            default:
                return state
        }
})

export default cloudSenseClusterReducer