import {
  USER_CREATE,
  USER_CREATE_FAILURE,
  USER_CREATE_SUCCESS,
  USER_VALIDATE_PASSWORD,
  GET_USER_ACTIVITY_LOGS,
  GET_USER_ACTIVITY_LOGS_SUCCESS,
  GET_USER_ACTIVITY_LOGS_FAILURE
} from './UserConstants'

const initialState = {
  isLoading: true,
  enrolledUser: {},
  message: '',
  activityLogs: []
}

export default function UserReducers(state = initialState, action) {
  switch (action.type) {
    case USER_CREATE:
      return {
        ...state,
        isLoading: true,
      }
    case USER_CREATE_FAILURE:
      return {
        ...state,
        isLoading: false,
        message: action.payload
      }
    case USER_CREATE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        enrolledUser: action.payload,
      }
    case GET_USER_ACTIVITY_LOGS:
      return {
        ...state,
        isLoading: true,
      }
    case GET_USER_ACTIVITY_LOGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        activityLogs: action.payload
      }
    case GET_USER_ACTIVITY_LOGS_FAILURE:
      return {
        ...state,
        isLoading: false,
        activityLogs: []
      }
    case USER_VALIDATE_PASSWORD:
    default:
      return state
  }
}
