/*
 *
 * UtakSession reducer
 *
 */
import produce from 'immer'
import { merge } from '../../utils/object'
import { DEFAULT_ACTION, UTAK_SESSION_MERGE } from './constants'

export const initialState = {
  isLogged: false,
  accesskey: '',
  sessionID: '',
  isLoading: false,
  error: '',
}

/* eslint-disable default-case, no-param-reassign */
const utakSessionReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case UTAK_SESSION_MERGE:
        return merge(draft, action.payload)
      case DEFAULT_ACTION:
        break
    }
  })

export default utakSessionReducer
