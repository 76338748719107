import {
  GET_KUBERNETES_VERSIONS,
  GET_KUBERNETES_VERSIONS_SUCCESS,
  GET_KUBERNETES_VERSIONS_FAILURE,
} from './KubernetesConstants'

const initialState = {
  isLoading: false,
  version_list: [],
}

export default function KubernetesReducers(state = initialState, action) {
  switch (action.type) {
    case GET_KUBERNETES_VERSIONS:
      return {
        ...state,
        isLoading: true,
      }
    case GET_KUBERNETES_VERSIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_KUBERNETES_VERSIONS_SUCCESS:
      const {
        payload: {
          version_list
        }
      } = action

      return {
        ...state,
        isLoading: false,
        version_list: version_list,
      }
    default:
      return state
  }
}
