import produce from 'immer'
import { AUTH_LOGOUT, AUTH_MERGE, AUTH_SELECTED_ORGANIZATION, AUTH_SET_ADMIN_VIEW } from './constants'
import { merge } from '../../utils/object'

// The initial state of the App
export const initialState = {
  isLogged: false,
  user: {
    username: '',
    token: '',
    mgtApiToken: '',
    organization_data: [],
    isAdmin: false, // not updated when user join to new org
  },
  selectedOrganization: {},
  userOrganizations: [],
  adminView: false,
  isLoading: false,
  error: '',
  permissions: null
}

const authReducer = (state = initialState, action) =>
  produce(state, draft => {
    const { payload = {} } = action
    switch (action.type) {
      case AUTH_MERGE:
        return merge(draft, action.payload)
      case AUTH_SELECTED_ORGANIZATION:
        return merge(draft, { selectedOrganization: payload })
      case AUTH_SET_ADMIN_VIEW:
        return merge(draft, { adminView: true})
      case AUTH_LOGOUT:
        return merge(draft, state )
    }
  })

export default authReducer
