/*
 *
 * AccountContainerRegistry reducer
 *
 */
import produce from 'immer'
import { merge } from '../../utils/object'
import { ACCOUNT_CONTAINER_REGISTRY_MERGE } from './constants'

export const initialState = {
  records: null,
  isLoading: true,
  isUpdated: false,
  error: '',
}

/* eslint-disable default-case, no-param-reassign */
const accountContainerRegistryReducer = (state = initialState, action) =>
  produce(state, draft => {
    switch (action.type) {
      case ACCOUNT_CONTAINER_REGISTRY_MERGE:
        return merge(draft, action.payload)
    }
  })

export default accountContainerRegistryReducer
