// mutate source
export const merge = (source, values) => {
  // handle object
  Object.keys(values).map(key => (source[key] = values[key]))
  return source
}

export const createMainHeaders = token => {
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Token ' + token,
    },
  }
}

export const createBearerHeaders = token => {
  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + token,
    },
  }
}

export const createBearerYamlHeaders = token => {
  return {
    headers: {
      'Content-Type': 'application/yaml',
      'Accept': 'application/yaml',
      Authorization: 'Bearer ' + token,
    },
  }
}

export const createHeaders = token => ({
  Authorization: 'Token ' + token,
})

export const createMultipartHeaders = token => {
  return {
    headers: {
      Authorization: 'Token ' + token,
    },
  }
}

export const createUtakHeaders = sesionID => {
  return {
    headers: {
      'utak-session-id': sesionID,
    },
  }
}

export const createUtakAndMainHeaders = (token, sesionID) => {
  return {
    headers: {
      'utak-session-id': sesionID,
      Authorization: 'Token ' + token,
    },
  }
}

export const createUtakProxyHeaders = (token, sesionID) => {
  return { headers: { Authorization: 'Bearer ' + sesionID } }
}

export const createManagementAPIHeaders = sesionID => ({
  headers: { Authorization: `Bearer ${sesionID}` },
})
