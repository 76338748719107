/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'

import rootAppReducer from './root/service/store/reducer'
import history from 'utils/history'
import languageProviderReducer from './containers/LanguageProvider/reducer'
// import authReducer from './route/auth/service/store/reducer'
import appReducer from './containers/App/reducer'
import utakSessionReducer from './containers/UtakSession/reducer'
import accountContainerRegistryReducer from './containers/AccountContainerRegistry/reducer'
import userAccountReducer from './containers/UserAccount/reducer'
import accountGeneralReducer from './containers/AccountGeneral/reducer'
import containerReducer from './containers/Container/reducer'
import authReducer from './containers/Auth/reducer'
import accountCloudProviderReducer from './containers/AccountCloudProvider/reducer'
import gcpServiceAccountKeysReducer from './containers/GcpServiceAccountKeys/reducer'
import accountCloudProviderAwsReducer from './containers/AccountCloudProviderAws/reducer'
import accountCloudProviderAzureReducer from './containers/AccountCloudProviderAzure/reducer'
import newUserHomeReducer from './containers/NewUserHome/reducer'
// import ibmReducer from './v2/cloud-sense/ibm/auth/ibmAuthReducers'
import { ibmReducer } from './v2/cloud-sense/ibm'
import cloudSenseClusterReducer from './v2/cloud-sense/cloud-sense/cluster/CloudSenseClusterReducer'
import cloudSenseUserReducer from './v2/cloud-sense/cloud-sense/user/CloudSenseUserReducers'
import cloudSenseOrganizationReducer from './v2/cloud-sense/cloud-sense/organization/CloudSenseOrgReducers'
import cloudSenseWorkspaceReducer from './v2/cloud-sense/cloud-sense/workspace/CloudSenseWorkspaceReducers'
import awsStorageReducer from './v2/cloud-sense/aws/storage/AwsStorageReducers'
import gcpStorageReducer from './v2/cloud-sense/gcp/storage/GcpStorageReducers'
import azureStorageReducer from './v2/cloud-sense/azure/storage/AzureStorageReducers'
import cloudSenseAppReducer from './v2/cloud-sense/cloud-sense/application/CloudSenseAppReducers'
import businessAppReducer from './v2/cloud-sense/business/products/BusinessProductsReducers'
import subscriptionAppReducer from './v2/cloud-sense/subscription/SubscriptionReducers'
import enrolledUserAppReducer from './v2/cloud-sense/user/UserReducers'
import enrolledOrganizationAppReducer from './v2/cloud-sense/organization/OrganizationReducers'
import hubspotAppReducer from './v2/cloud-sense/enrollment/hubspot/HubspotReducers'
import validateEnrollmentAppReducer from './v2/cloud-sense/enrollment/validate/EnrollmentReducers'
import organizationInvoiceReducer from './v2/cloud-sense/organization/management/invoices/InvoicesReducers'
import organizationInvoiceSubscription from './v2/cloud-sense/organization/management/subscriptions/SubscriptionReducers'
import organizationPaymentMethod from './v2/cloud-sense/organization/management/payment-method/PaymentMethodReducers'
import organizationProduct from './v2/cloud-sense/organization/products/OrganizationProductReducers'
import kubernetesReducer from './v2/cloud-sense/cluster/Kubernetes/KubernetesReducers'
import componentVersionReducer from './v2/cloud-sense/cluster/ComponentVersions/ComponentVersionsReducers'
import versionControlAccountsReducer from './containers/VersionControlAccounts/reducer'
import CloudSenseRepositoryReducers from './v2/cloud-sense/cloud-sense/repository/CloudSenseRepositoryReducers'
import AwsEksUsersReducers from './v2/cloud-sense/aws/users/AwsUsersReducers'
import AwsClusterReducers from './v2/cloud-sense/aws/cluster/AwsClusterReducers'
import cloudSenseLoggingReducer from './v2/cloud-sense/cloud-sense/logging/CloudSenseLoggingReducers'
/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  const rootReducer = combineReducers({
    accountContainerRegistry: accountContainerRegistryReducer,
    accountCloudProviderAws: accountCloudProviderAwsReducer,
    accountCloudProviderAzure: accountCloudProviderAzureReducer,
    accountCloudProvider: accountCloudProviderReducer,
    accountGeneral: accountGeneralReducer,
    app: appReducer,
    auth: authReducer,
    container: containerReducer,
    cluster: cloudSenseClusterReducer,
    ibm: ibmReducer,
    organization: cloudSenseOrganizationReducer,
    workspace: cloudSenseWorkspaceReducer,
    aws: awsStorageReducer,
    awsCluster: AwsClusterReducers,
    eksUsers: AwsEksUsersReducers,
    gcp: gcpStorageReducer,
    azure: azureStorageReducer,
    user: cloudSenseUserReducer,
    application: cloudSenseAppReducer,
    language: languageProviderReducer,
    gcpServiceAccountKeys: gcpServiceAccountKeysReducer,
    newUserHome: newUserHomeReducer,
    router: connectRouter(history),
    root: rootAppReducer,
    userAccount: userAccountReducer,
    utakSession: utakSessionReducer,
    products: businessAppReducer,
    subscription: subscriptionAppReducer,
    enrolledUser: enrolledUserAppReducer,
    enrolledOrganization: enrolledOrganizationAppReducer,
    hubspot: hubspotAppReducer,
    enrollment: validateEnrollmentAppReducer,
    organizationInvoices: organizationInvoiceReducer,
    organizationSubscription: organizationInvoiceSubscription,
    organizationPaymentMethod: organizationPaymentMethod,
    organizationProduct: organizationProduct,
    repositories: CloudSenseRepositoryReducers,
    kubernetesVersion: kubernetesReducer,
    componentVersions: componentVersionReducer,
    versionControlAccounts: versionControlAccountsReducer,
    logging: cloudSenseLoggingReducer,
    ...injectedReducers,
  })

  return rootReducer
}
