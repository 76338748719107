import {
  GET_CLUSTER_COMPONENT_VERSIONS,
  GET_CLUSTER_COMPONENT_VERSIONS_SUCCESS,
  GET_CLUSTER_COMPONENT_VERSIONS_FAILURE
} from './ComponentVersionsConstants'

const initialState = {
  isLoading: false,
  version_list: {},
}

export default function ComponentVersionsReducers(state = initialState, action) {
  switch (action.type) {
    case GET_CLUSTER_COMPONENT_VERSIONS:
      return {
        ...state,
        isLoading: true,
      }
    case GET_CLUSTER_COMPONENT_VERSIONS_FAILURE:
      return {
        ...state,
        isLoading: false,
      }
    case GET_CLUSTER_COMPONENT_VERSIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        version_list: action.payload,
      }
    default:
      return state
  }
}
