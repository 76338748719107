import {
  HUBSPOT_CONTACT_GET,
  HUBSPOT_CONTACT_GET_SUCCESS,
  HUBSPOT_CONTACT_GET_FAILURE,
} from './HubspotConstants'

const initialState = {
    isLoading: true,
    hubspot: {},
    message: ''
  }

export default function HubspotReducers(state = initialState, action) {
    switch (action.type) {
      case HUBSPOT_CONTACT_GET:
        return {
          ...state,
          isLoading: true,
        }
      case HUBSPOT_CONTACT_GET_FAILURE:
        return {
          ...state,
          isLoading: false,
          message: action.payload
        }
      case HUBSPOT_CONTACT_GET_SUCCESS:
        return {
          ...state,
          isLoading: false,
          hubspot: action.payload,
        }
      default:
        return state
    }
  }