import React from 'react'
import clsx from 'clsx'

import MuSnackbar from '@material-ui/core/Snackbar'
import SnackbarContent from '@material-ui/core/SnackbarContent'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import CloseIcon from '@material-ui/icons/Close'
import IconButton from '@material-ui/core/IconButton'
import WarningIcon from '@material-ui/icons/Warning'

import { useStyles } from './style'

const autoHideDuration = 4000 // 6 sec
const anchorOrigin = {
  horizontal: 'center',
  vertical: 'top',
}
const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
}

const SnackBar = ({
  message = '',
  variant = 'info',
  onClose = () => {},
  className,
  open,
  ...other
}) => {
  const classes = useStyles()
  const Icon = variantIcon[variant]

  return (
    <MuSnackbar
      anchorOrigin={anchorOrigin}
      open={open}
      autoHideDuration={autoHideDuration}
      onClose={onClose}
    >
      <SnackbarContent
        className={clsx(classes[variant], className)}
        message={
          <span className={classes.message}>
            <Icon className={clsx(classes.icon, classes.iconVariant)} />
            {message}
          </span>
        }
        action={[
          <IconButton
            key='close'
            aria-label='Close'
            color='inherit'
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>,
        ]}
        {...other}
      />
    </MuSnackbar>
  )
}

export default SnackBar
