import {
    CLOUD_SENSE_CREATE_APPLICATION,
    CLOUD_SENSE_CREATE_APPLICATION_FAILURE,
    CLOUD_SENSE_CREATE_APPLICATION_SUCCESS,
    CLOUD_SENSE_GET_APPLICATIONS,
    CLOUD_SENSE_GET_APPLICATIONS_FAILURE,
    CLOUD_SENSE_GET_APPLICATIONS_SUCCESS,
    CLOUD_SENSE_DELETE_APPLICATION,
    CLOUD_SENSE_DELETE_APPLICATION_FAILURE,
    CLOUD_SENSE_DELETE_APPLICATION_SUCCESS,
    CLOUD_SENSE_REGISTER_CUSTOM_APPLICATION,
    CLOUD_SENSE_GET_CUSTOM_APPLICATIONS,
    CLOUD_SENSE_GET_CUSTOM_APPLICATIONS_SUCCESS,
    CLOUD_SENSE_DELETE_CUSTOM_APPLICATION,
    CLOUD_SENSE_UPDATE_CUSTOM_APPLICATION,
    CLOUD_SENSE_UPDATE_CUSTOM_APPLICATION_SUCCESS,
    CLOUD_SENSE_UPDATE_CUSTOM_APPLICATION_FAILURE,
    CLOUD_SENSE_GET_DEPLOYED_CUSTOM_APPLICATIONS,
    CLOUD_SENSE_GET_DEPLOYED_CUSTOM_APPLICATIONS_SUCCESS,
    CLOUD_SENSE_DEREGISTER_CUSTOM_APPLICATION,
    CLOUD_SENSE_GET_DEPLOYED_APPLICATION_DETAILS,
    CLOUD_SENSE_GET_DEPLOYED_APPLICATION_DETAILS_SUCCESS,
    CLOUD_SENSE_GET_DEPLOYED_APPLICATION_DETAILS_FAILURE,
    CLOUD_SENSE_GET_CUSTOM_APPLICATIONS_FAILURE,
    CLOUD_SENSE_GET_DEPLOYED_CUSTOM_APPLICATIONS_FAILURE
} from './CloudSenseAppContants'

const initialState = {
    isGetting: false,
    isGettingCustomApplications: false,
    isGettingDeployedCustomApplications: false,
    isCreating: false,
    isDeleting: false,
    isUpdating: false,
    isUpdatingDetails: false,
    custom: [],
    customDeployed: [],
    applicationDetails: {},
    application_list: []
}

export default function CloudSenseAppReducers(state=initialState, action) {
    switch(action.type) {
        case CLOUD_SENSE_REGISTER_CUSTOM_APPLICATION:
        case CLOUD_SENSE_CREATE_APPLICATION:
            return {
                ...state,
                isCreating: true
            }
        case CLOUD_SENSE_CREATE_APPLICATION_FAILURE:
        case CLOUD_SENSE_CREATE_APPLICATION_SUCCESS:
            return {
                ...state,
                isCreating: false
            }
        case CLOUD_SENSE_GET_DEPLOYED_CUSTOM_APPLICATIONS:
            return {
                ...state,
                isGettingDeployedCustomApplications: true,
            }
        case CLOUD_SENSE_GET_CUSTOM_APPLICATIONS:
            return {
                ...state,
                isGettingCustomApplications: true,
            }
        case CLOUD_SENSE_GET_APPLICATIONS:
            return {
                ...state,
                isGetting: true
            }
        case CLOUD_SENSE_GET_APPLICATIONS_FAILURE:
            return {
                ...state,
                isGetting: false
            }
        case CLOUD_SENSE_GET_DEPLOYED_CUSTOM_APPLICATIONS_FAILURE:
            return {
                ...state,
                isGettingDeployedCustomApplications: false,
            }
        case CLOUD_SENSE_GET_CUSTOM_APPLICATIONS_FAILURE:
            return {
                ...state,
                isGettingCustomApplications: false,
            }
        case CLOUD_SENSE_GET_DEPLOYED_CUSTOM_APPLICATIONS_SUCCESS:
            return {
                ...state,
                isGettingDeployedCustomApplications: false,
                customDeployed: action.payload
            }
        case CLOUD_SENSE_GET_CUSTOM_APPLICATIONS_SUCCESS:
            return {
                ...state,
                isGettingCustomApplications: false,
                custom: action.payload
            }
        case CLOUD_SENSE_GET_APPLICATIONS_SUCCESS:
            return {
                ...state,
                isGetting: false,
                ...action.payload
            }
        case CLOUD_SENSE_DELETE_APPLICATION:
        case CLOUD_SENSE_DELETE_CUSTOM_APPLICATION:
        case CLOUD_SENSE_DEREGISTER_CUSTOM_APPLICATION:
            return {
                ...state,
                isDeleting: true
            }
        case CLOUD_SENSE_DELETE_APPLICATION_FAILURE:
        case CLOUD_SENSE_DELETE_APPLICATION_SUCCESS:
            return {
                ...state,
                isDeleting: false
            }
        case CLOUD_SENSE_UPDATE_CUSTOM_APPLICATION:
            return {
                ...state,
                isUpdating: true
            }
        case CLOUD_SENSE_UPDATE_CUSTOM_APPLICATION_SUCCESS:
        case CLOUD_SENSE_UPDATE_CUSTOM_APPLICATION_FAILURE:
            return {
                ...state,
                isUpdating: false
            }
        case CLOUD_SENSE_GET_DEPLOYED_APPLICATION_DETAILS:
            return {
                ...state,
                isUpdatingDetails: true,
            }
        case CLOUD_SENSE_GET_DEPLOYED_APPLICATION_DETAILS_SUCCESS:
            return {
                ...state,
                isUpdatingDetails: false,
                applicationDetails: action.payload,
            }
        case CLOUD_SENSE_GET_DEPLOYED_APPLICATION_DETAILS_FAILURE:
            return {
                ...state,
                isUpdatingDetails: false,
            }
        default:
            return state
    }
}