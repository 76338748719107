export const CLOUD_SENSE_CREATE_WORKSPACE = 'CLOUD_SENSE_CREATE_WORKSPACE'
export const CLOUD_SENSE_CREATE_WORKSPACE_SUCCESS =
  'CLOUD_SENSE_CREATE_WORKSPACE_SUCCESS'
export const CLOUD_SENSE_CREATE_WORKSPACE_FAILURE =
  'CLOUD_SENSE_CREATE_WORKSPACE_FAILURE'
export const CLOUD_SENSE_DELETE_WORKSPACE = 'CLOUD_SENSE_DELETE_WORKSPACE'
export const CLOUD_SENSE_DELETE_WORKSPACE_SUCCESS =
  'CLOUD_SENSE_DELETE_WORKSPACE_SUCCESS'
export const CLOUD_SENSE_DELETE_WORKSPACE_FAILURE =
  'CLOUD_SENSE_CREATE_WORKSPACE_FAILURE'
export const CLOUD_SENSE_GET_WORKSPACE = 'CLOUD_SENSE_GET_WORKSPACE'
export const CLOUD_SENSE_GET_WORKSPACE_SUCCESS =
  'CLOUD_SENSE_GET_WORKSPACE_SUCCESS'
export const CLOUD_SENSE_GET_WORKSPACE_FAILURE =
  'CLOUD_SENSE_GET_WORKSPACE_FAILURE'
export const CLOUD_SENSE_GET_WORKSPACE_USERS = 'CLOUD_SENSE_GET_WORKSPACE_USERS'
export const CLOUD_SENSE_GET_WORKSPACE_USERS_SUCCESS =
  'CLOUD_SENSE_GET_WORKSPACE_USERS_SUCCESS'
export const CLOUD_SENSE_GET_WORKSPACE_USERS_FAILURE =
  'CLOUD_SENSE_GET_WORKSPACE_USERS_FAILURE'
export const CLOUD_SENSE_ADD_WORKSPACE_USERS =
  'CLOUD_SENSE_CREATE_ADD_WORKSPACE_USERS'
export const CLOUD_SENSE_ADD_WORKSPACE_USERS_SUCCESS =
  'CLOUD_SENSE_ADD_WORKSPACE_USERS_SUCCESS'
export const CLOUD_SENSE_ADD_WORKSPACE_USERS_FAILURE =
  'CLOUD_SENSE_ADD_WORKSPACE_USERS_FAILURE'
export const IMPORT_CLUSTER_WORKSPACE = 'IMPORT_CLUSTER_WORKSPACE'
export const IMPORT_CLUSTER_WORKSPACE_SUCCESS =
  'IMPORT_CLUSTER_WORKSPACE_SUCCESS'
export const IMPORT_CLUSTER_WORKSPACE_FAILURE =
  'IMPORT_CLUSTER_WORKSPACE_FAILURE'

// remove user
export const CLOUD_SENSE_REMOVE_USER_FROM_WORKSPACE =
  'CLOUD_SENSE_REMOVE_USER_FROM_WORKSPACE'
export const CLOUD_SENSE_REMOVE_USER_FROM_WORKSPACE_SUCCESS =
  'CLOUD_SENSE_REMOVE_USER_FROM_WORKSPACE_SUCCESS'
export const CLOUD_SENSE_REMOVE_USER_FROM_WORKSPACE_FAILURE =
  'CLOUD_SENSE_REMOVE_USER_FROM_WORKSPACE_FAILURE'

export const CLOUD_SENSE_SWITCH_WORKSPACE = 
  'CLOUD_SENSE_SWITCH_WORKSPACE'

export const CLOUD_SENSE_GET_WORKSPACE_BY_ID = 'CLOUD_SENSE_GET_WORKSPACE_BY_ID'