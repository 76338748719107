import { loadDB, saveDB } from './helper'

// temp user db for prototype only
// will be shift to api service

let Users = {}
const dbKey = 'Users'

// check email if already registered using email
const isRegistered = email => {
  return !!Users[email]
}

// check user if registered using email, password
const isRegisteredWithPassword = (email, password) => {
  if (!Users[email]) {
    return false
  }
  return Users[email].password === password
}

// register a user
const register = (email, password) => {
  if (Users[email]) {
    return {
      code: 300,
      message: 'already a member',
    }
  }

  Users[email] = {
    email,
    password,
  }
  // save to virtual db
  saveDB(dbKey, Users)
  // return result
  return {
    code: 200,
    message: 'successfuly registered',
  }
}

// initialize on page load

const runStorageInit = async () => {
  Users = await loadDB(dbKey)
}

export { isRegistered, isRegisteredWithPassword, register, runStorageInit }
