import { loadDB, saveDB } from './helper'
import { runApiInit } from '../api'

let Auth = {
  user: {
    token: '',
    username: '',
    isAdmin: false,
  },
}

const dbKey = 'Auth'

const isLogged = () => {
  return Auth.isLogged
}

const userLogged = () => {
  return Auth.user
}

const isAdmin = () => {
  return Auth.isLogged  && Auth.user.isAdmin === true
}
 
const login = async (user, token) => {
  Auth = {
    isLogged: true,
    user: user,
    token: token,
  }

  await saveDB(dbKey, Auth)
}

const storeUser = async (user, token) => {
  Auth = {
    isLogged: true,
    user: user,
    selectedOrganization: user.organization_data[0],
    token: token,
    adminView: false,
  }

  await saveDB(dbKey, Auth)
}

const loggout = async () => {
  Auth = {
    isLogged: false,
    user: {},
    adminView: false,
  }
  saveDB(dbKey, Auth)


  const preferredOrganization = localStorage.getItem('cloudsense_preferred_organization');
  await localStorage.clear()

  //persist some local storage on logout
  localStorage.setItem('cloudsense_preferred_organization', preferredOrganization)
}

const runStorageInit = async () => {
  Auth = await loadDB(dbKey)
  await runApiInit(Auth)
}

const loadUser = async () => {
  Auth = await loadDB(dbKey)
  if (Auth) {
    return Auth
  }
  return {}
}

export {
  loadUser,
  runStorageInit,
  login,
  loggout,
  isLogged,
  userLogged,
  storeUser,
  isAdmin,
}
