export const IBM_CREATE_BUCKET = 'IBM_CREATE_BUCKET'
export const IBM_CREATE_BUCKET_SUCCESS = 'IBM_CREATE_BUCKET_SUCCESS'
export const IBM_CREATE_BUCKET_FAILURE = 'IBM_CREATE_BUCKET_FAILURE'
export const IBM_DELETE_BUCKET = 'IBM_DELETE_BUCKET'
export const IBM_DELETE_BUCKET_SUCCESS = 'IBM_DELETE_BUCKET_SUCCESS'
export const IBM_DELETE_BUCKET_FAILURE = 'IBM_DELETE_BUCKET_FAILURE'
export const IBM_GET_BUCKETS = 'IBM_GET_BUCKETS'
export const IBM_GET_BUCKETS_SUCCESS = 'IBM_GET_BUCKETS_SUCCESS'
export const IBM_GET_BUCKETS_FAILURE = 'IBM_GET_BUCKETS_FAILURE'
export const IBM_GET_BUCKET_OBJECTS = 'IBM_GET_BUCKET_OBJECTS'
export const IBM_GET_BUCKET_OBJECTS_SUCCESS = 'IBM_GET_BUCKET_OBJECTS_SUCCESS'
export const IBM_GET_BUCKET_OBJECTS_FAILURE = 'IBM_GET_BUCKET_OBJECTS_FAILURE'