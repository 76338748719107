import api from '../../../../constant/api'
import { refreshableFetch } from '../../../../utils/fetch'

export const putIBMCredentials = async (apiKey, token, organizationId) => {
    return await refreshableFetch(`${api.ibm}/credentials/${organizationId}`, {
        method: 'PUT',
        headers: {
            'Authorization': 'Bearer ' + token,
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        body: JSON.stringify({
            apiKey
        })
    })
}

export const deleteIBMCredentials = async (token, organizationId) => {
    return await refreshableFetch(`${api.cloudCredentials}/delete/${organizationId}?provider=ibm`, {
        method: 'DELETE',
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
}

export const getIBMCredentials = async (token, organizationId) => {
    return await refreshableFetch(`${api.ibm}/credentials/${organizationId}`, {
        headers: {
            'Authorization': 'Bearer ' + token
        }
    })
}