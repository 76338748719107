// path list

export const index = '/'

export const activate = '/activate'

export const create_org = '/create-organization'

export const auth = '/auth/'
export const auth_login = '/auth/login'
export const auth_registration = '/auth/registration'
export const auth_start = '/auth/start/'
export const auth_reset_password = '/auth/reset'

export const persona = '/persona'

export const error_page = '/not-found'

export const home = '/home'

export const developerHome = '/developer-home'
export const workspace = '/workspace'
export const data = '/data'
export const applications = '/applications'
export const jobs = '/jobs'
export const ulapAdmin_developerHome = '/admin/developer-home'

//super admin/ulap admin routes
export const ulap_admin = '/admin'
export const ulapAdmin_home = '/admin/home'

export const dashboard = '/dashboard'
export const dashboard_home = '/dashboard/home/'
export const dashboard_welcome = '/dashboard/welcome/'
export const dashboard_graph = '/dashboard/graph/'
export const dashboard_kubernetes = '/dashboard/kubernetes/'
export const dashboard_createCluster = '/dashboard/create-cluster/'

//cluster report
export const dashboard_clusterReport = '/cluster/report'
export const dashboard_clusterGraph = `/overview`
export const dashboard_clusterNodes = `/nodes`
export const dashboard_clusterPods = `/workloads/pods`
export const dashboard_clusterDeployment = `/workloads/deployments`
export const dashboard_clusterDaemonset = `/workloads/daemonsets`
export const dashboard_clusterStatefulSet = `/workloads/statefulsets`
export const dashboard_clusterPVC = `/storage/persistent-volume-claims`
export const dashboard_clusterPV = `/storage/persistent-volume`
export const dashboard_clusterContainer = `/container`
export const dashboard_clusterService = `/network/services`
export const dashboard_clusterIngress = `/network/ingresses`
export const dashboard_clusterNamespace = `/namespaces`
export const dashboard_clusterEvent = `/events`
export const dashboard_clusterCostExplorer = '/cost'
export const dashboard_clusterGpuExplorer = '/gpu-explorer'
export const dashboard_clusterLogDashboard = '/log-dashboard'

export const account = '/account' // general
export const account_general = '/account/general' // general
export const account_cloudProvider = '/account/cloud-provider'
export const account_containerRegistry = '/account/container-registry'
export const account_containerOrganization = '/account/organization'
export const account_containerCallback =
  '/account/container-registry/:provider/'

export const cluster = '/clusters/'
export const cluster_wizard = '/clusters/create-wizard'
export const cluster_create = '/clusters/create'
export const cluster_list = '/clusters/list'
export const cluster_connect = '/clusters/connect'
export const cluster_cloudsense_templates = '/clusters/cloudsense-templates'

export const container = '/container/'
export const container_create = '/container/create'
export const container_list = '/container/list'

export const welcome = '/welcome/'

export const onboarding = '/getting-started/'
export const onboarding_cloud_providers = '/getting-started/cloud-providers'
export const onboarding_container_registry =
  '/getting-started/container-registry'
export const onboarding_cloud_repositories =
  '/getting-started/cloud-repositories'

export const deployment = '/deployment/'
export const deployment_list = '/deployment/list'

export const spark = '/spark/'
export const spark_list = '/spark/list'
export const spark_create = '/spark/create'

export const users = '/users/'
export const users_list = '/users/list'

export const organization = '/organization/'
export const organization_list = '/organization/list'
export const organization_settings = '/organization/settings'
export const organization_create = '/organization/create'

export const members = '/members/'
export const members_list = '/members/list'

export const nodepools = '/nodepools/'
export const nodepools_list = '/nodepools/list'
export const nodepools_create = '/nodepools/create'

export const node = '/node/'
export const node_list = '/node/list'

// revamped org settings
export const organization_management = '/organization/management'
export const organization_profile = `${organization_management}/profile`
export const organization_users = `${organization_management}/users`
export const organization_cloud_providers = `${organization_management}/cloud-providers`
export const organization_billing = `${organization_management}/billing`

// pdf render
export const pdf_render = '/invoice'
