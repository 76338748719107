/*
 *
 * AccountCloudProviderAws constants
 *
 */

export const ACCOUNT_CLOUD_PROVIDER_AWS_MERGE =
  'app/AccountCloudProviderAws/ACCOUNT_CLOUD_PROVIDER_AWS_MERGE'

export const ACCOUNT_CLOUD_PROVIDER_AWS_FETCH =
  'app/AccountCloudProviderAws/ACCOUNT_CLOUD_PROVIDER_AWS_FETCH'
export const ACCOUNT_CLOUD_PROVIDER_AWS_SAVE =
  'app/AccountCloudProviderAws/ACCOUNT_CLOUD_PROVIDER_AWS_SAVE'
  export const ACCOUNT_CLOUD_PROVIDER_AWS_DELETE =
    'app/AccountCloudProviderAws/ACCOUNT_CLOUD_PROVIDER_AWS_DELETE' 