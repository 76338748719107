export const CLOUD_SENSE_SAVE_USER_PROFILE = 'CLOUD_SENSE_SAVE_USER_PROFILE'
export const CLOUD_SENSE_SAVE_USER_PROFILE_SUCCESS =
  'CLOUD_SENSE_SAVE_USER_PROFILE_SUCCESS'
export const CLOUD_SENSE_SAVE_USER_PROFILE_FAILURE =
  'CLOUD_SENSE_SAVE_USER_PROFILE_FAILURE'

export const CLOUD_SENSE_GET_USER_PROFILE = 'CLOUD_SENSE_GET_USER_PROFILE'
export const CLOUD_SENSE_GET_USER_PROFILE_SUCCESS =
  'CLOUD_SENSE_GET_USER_PROFILE_SUCCESS'
export const CLOUD_SENSE_GET_USER_PROFILE_FAILURE =
  'CLOUD_SENSE_GET_USER_PROFILE_FAILURE'

export const personaTypes = ['Developer & Data Scientist', 'DevOps']

export const CLOUD_SENSE_SEND_USER_RESET_PASSWORD_TOKEN =
  'CLOUD_SENSE_SEND_USER_RESET_PASSWORD_TOKEN'
export const CLOUD_SENSE_CHANGE_PASSWORD = 'CLOUD_SENSE_CHANGE_PASSWORD'

export const CLOUD_SENSE_SYNC_USER_SSO = 'CLOUD_SENSE_SYNC_USER_SSO'