import {
    GET_ORGANIZATION_PRODUCT,
    GET_ORGANIZATION_PRODUCT_SUCCESS,
    GET_ORGANIZATION_PRODUCT_FAILURE,
} from './OrganizationProductContants'

const initialState = {
    isGetting: false,
    organizationProduct: {}
}

export default function OrganizationProductReducers(state=initialState, action) {
    switch(action.type) {
        case GET_ORGANIZATION_PRODUCT:
            return {
                ...state,
                isGetting: true
            }
        case GET_ORGANIZATION_PRODUCT_FAILURE:
        case GET_ORGANIZATION_PRODUCT_SUCCESS:
            return {
                ...state,
                isGetting: false,
                organizationProduct: action.payload
            }
        default:
            return state
    }
}