import {
    CLOUD_SENSE_GET_ALL_ORGS_AND_WORKSPACES,
    CLOUD_SENSE_GET_ALL_ORGS_AND_WORKSPACES_SUCCESS,
    CLOUD_SENSE_GET_ALL_ORGS_AND_WORKSPACES_FAILURE,
    CLOUD_SENSE_GET_ORG_USERS,
    CLOUD_SENSE_GET_ORG_USERS_FAILURE,
    CLOUD_SENSE_GET_ORG_USERS_SUCCESS,
    CLOUD_SENSE_GET_ORG_WORKSPACES,
    CLOUD_SENSE_GET_ORG_WORKSPACES_FAILURE,
    CLOUD_SENSE_GET_ORG_WORKSPACES_SUCCESS
} from './CloudSenseOrgConstants'

const initialState = {
    workspaces: [],
    users: [],
    isGetting: false,
    allCloudSenseOrganizationsAndWorkspaces: []
}

export default function CloudSenseOrgReducers(state=initialState, action) {
    switch(action.type) {
        case CLOUD_SENSE_GET_ORG_USERS:
        case CLOUD_SENSE_GET_ORG_WORKSPACES:
            return {
                ...state,
                isGetting: true
            }
        case CLOUD_SENSE_GET_ORG_USERS_FAILURE:
        case CLOUD_SENSE_GET_ORG_WORKSPACES_FAILURE:
            return {
                ...state,
                isGetting: false
            }
        case CLOUD_SENSE_GET_ORG_USERS_SUCCESS:
            return {
                ...state,
                isGetting: false,
                users: [...action.payload.data, ...action.payload.invitees]
            }
        case CLOUD_SENSE_GET_ORG_WORKSPACES_SUCCESS:
            return {
                ...state,
                isGetting: false,
                workspaces: action.payload
            }
        case CLOUD_SENSE_GET_ALL_ORGS_AND_WORKSPACES:
            return {
                ...state,
                isGetting: true
            }
        case CLOUD_SENSE_GET_ALL_ORGS_AND_WORKSPACES_FAILURE:
            return {
                ...state,
                isGetting: false
            }
        case CLOUD_SENSE_GET_ALL_ORGS_AND_WORKSPACES_SUCCESS:
            return {
                ...state,
                isGetting: false,
                allCloudSenseOrganizationsAndWorkspaces: action.payload
            }
        default:
            return state
    }
}