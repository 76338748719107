import { storageVersion } from '../../constant/version'

//
//
// must be refactored
//
//
const dbKey = 'ulap_' + storageVersion + ' '

export const loadDB = async key => {
  const db = await localStorage.getItem(dbKey + key)

  if (db) {
    try {
      return JSON.parse(db)
    } catch (e) {
      console.log(e)
      return {}
    }
  }
  return {}
}

export const saveDB = async (key, data) => {
  try {
    localStorage.setItem(dbKey + key, JSON.stringify(data))
  } catch (e) {
    console.log(e)
  }
}
