export const root_name = 'auth'

export const AUTH_MERGE = 'project/auth/ROOT_MERGE'
// saga dispatcher
export const AUTH_VALIDATE_USERNAME = 'project/auth/AUTH_VALIDATE_USERNAME'
export const AUTH_LOGIN = 'project/auth/AUTH_LOGIN'
export const AUTH_LOGOUT = 'project/auth/AUTH_LOGOUT'
export const AUTH_REGISTER = 'project/auth/AUTH_REGISTER'
export const AUTH_SELECTED_ORGANIZATION =
  'project/auth/AUTH_SELECTED_ORGANIZATION'
export const AUTH_FETCH_USER_ORGANIZATION =
  'project/auth/AUTH_FETCH_USER_ORGANIZATION'
export const AUTH_FETCH_PERMISSIONS = 'project/auth/AUTH_FETCH_PERMISSIONS'
export const AUTH_SET_ADMIN_VIEW = 'project/auth/AUTH_SET_ADMIN_VIEW'
