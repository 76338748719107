const devMgtUrl = 'http://localhost:5000'
const devMgtUrlV2 = 'http://127.0.0.1:5010'
const devBusinessUrl = 'http://localhost:8000'
const devStripePK = 'pk_test_4NBMDgHqFVQ8K9SgCCjnMom0007JuPxBVS'
export const devGcpClientId = process.env.GCP_DEV_CLIENT_ID

const prodMgtUrl = process.env.CLOUDSENSE_MANAGEMENT_API_URL || window._env_.CLOUDSENSE_MANAGEMENT_API_URL;
const prodMgtUrlV2 = process.env.CLOUDSENSE_MANAGEMENT_API_URL_V2 || window._env_.CLOUDSENSE_MANAGEMENT_API_URL_V2;
const prodBusinessUrl = process.env.CLOUDSENSE_BUSINESS_API_URL || window._env_.CLOUDSENSE_BUSINESS_API_URL;
const prodStripePK = process.env.PROD_STRIPE_PK || window._env_.PROD_STRIPE_PK;

export const prodGcpClientId = process.env.GCP_PROD_CLIENT_ID || window._env_.GCP_PROD_CLIENT_ID;

export const environment = env => {
  let managementUrl
  let managementUrlV2
  let businessUrl
  let stripePK

  switch (env) {
    case 'production':
      managementUrl = prodMgtUrl;
      managementUrlV2 = prodMgtUrlV2;
      stripePK = prodStripePK;
      businessUrl = prodBusinessUrl;
      break;
    case 'development':
    case 'local':
      managementUrl = devMgtUrl;
      managementUrlV2 = devMgtUrlV2;
      businessUrl = devBusinessUrl;
      stripePK = devStripePK;
      break;
    default:
      managementUrl = prodMgtUrl;
      managementUrlV2 = prodMgtUrlV2;
      businessUrl = prodBusinessUrl;
      stripePK = devStripePK;
      break;
  }

  return {
    auth: '/auth/',
    azureResource: managementUrl + '/api/v1/azure_resource/',
    avatarUpload: '/uploads/v1/',
    base: '/',
    clusters: managementUrl + '/api/v1/cluster',
    users: managementUrl + '/api/v1/user',
    clustersNode: '/api/v1/clusters/nodes',
    container: '/api/v1/container',
    containerList: '/deployments/v1',
    nodepoolCreate: managementUrl + '/api/v1/nodepool/create',
    services: managementUrl + '/api/v1/cluster/services/list',
    mngtCluster: managementUrl + '/api/v1/cluster',

    dockerhub: managementUrl + '/api/v1/dockerhub',
    fileUpload: '/v1/file-uploader',
    gcpServiceAccountKeys: managementUrl + '/api/v1/user/gcp-service-account-keys/',
    githubApi: '/user-auth-github/v1/',
    githubRepos: '/github-repos/v1/',
    newUserConfig: managementUrl + '/api/v1/user/new-user-config',
    sparkStatus: '/clusters/spark-list',
    cloudsenseRegister: managementUrl + '/api/v1/user/create',
    userAccount: '/user-account/v1/',
    userCount: managementUrl + '/api/v1/user/admin/total',
    userAuthGcp: managementUrl + '/api/v1/user/user-auth-gcp',
    userAuthAws: managementUrl + '/api/v1/organization/user_auth_aws',
    userAuthAzure: managementUrl + '/api/v1/organization/user_auth_azure',
    userAuthDockerhub: managementUrl + '/api/v1/user/user_auth_dockerhub',
    userCloudProvider: '/user-cloud-provider/v1/',
    userContainerRegistry: managementUrl + '/api/v1/user/user-cloud-provider',
    organization: managementUrl + '/api/v1/organization',
    application: managementUrl + '/api/v1/application',
    userForgotPassword: managementUrl + '/api/v1/user/forgot',
    userChangePassword: managementUrl + '/api/v1/user/reset',
    cluster_import: managementUrl + '/api/v1/workspace/cluster/import',
    workspace: managementUrl + '/api/v1/workspace',
    sparkProxy: '/api/v1/spark/proxy',
    sparkLilySvc: '/api/v1/spark/lily-svc',
    validator: '/validator/',
    mgtApi: managementUrl + '/api/v1/',
    mgtApiV2: managementUrlV2,
    products: businessUrl + '/products',
    subscriptions: businessUrl + '/subscriptions',
    userEnrollment: businessUrl + '/users',
    enrollment: businessUrl + '/enrollment/organization',
    organizationEnrollment: businessUrl + '/organization',
    validateEnrollment: businessUrl + '/enrollment/validate',
    getHubspotContacts: businessUrl + '/hubspot/contacts',
    refreshToken: managementUrl + '/api/v1/user/refresh',
    invoices: businessUrl + '/invoices',
    paymentMethods: businessUrl + '/payment-methods',
    organization_invite: businessUrl + '/email/organization/invite',
    stripePK,

    // V2
    gcp: managementUrlV2 + '/gcp',
    refreshTokenV2: managementUrlV2 + '/user/refresh',
    aws: managementUrlV2 + '/aws',
    ibm: managementUrlV2 + '/ibm',
    login: managementUrlV2 + '/user/login',
    azure: managementUrlV2 + '/azure',
    organizationV2: managementUrlV2 + '/organization',
    configTemplate: managementUrlV2 + '/config-template',
    clusterV2: managementUrlV2 + '/cluster',
    machineType: managementUrlV2 + '/machine-type',
    userProfile: managementUrlV2 + '/user/profile',
    workspaceV2: managementUrlV2 + '/workspace',
    permission: managementUrlV2 + '/permission',
    cloudCredentials: managementUrlV2 + '/cloud-credentials',
    metrics: managementUrlV2 + '/metrics',
    codeRepositoryCredentials: managementUrlV2 + '/code-repository-credentials',
    applicationsV2: managementUrlV2 + '/applications',
    nodepoolsV2: managementUrlV2 + '/nodepools',
    kubeconfig: managementUrlV2 + '/kubeconfig',
    user: managementUrlV2 +'/user',
    loginVerify: managementUrlV2 + '/user/login_verify',
    user_activity: managementUrlV2 + '/user_activity',
    gitlab: managementUrlV2 + '/gitlab',
    github: managementUrlV2 + '/github',
  }
}

export default environment(process.env.NODE_ENV || window._env_.NODE_ENV)