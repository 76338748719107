/*
 *
 * AccountGeneral constants
 *
 */

export const DEFAULT_ACTION = 'app/AccountGeneral/DEFAULT_ACTION'
export const ACCOUNT_GENERAL_MERGE = 'app/AccountGeneral/ACCOUNT_GENERAL_MERGE'
export const ACCOUNT_GENERAL_FETCH = 'app/AccountGeneral/ACCOUNT_GENERAL_FETCH'
export const ACCOUNT_GENERAL_SAVE = 'app/AccountGeneral/ACCOUNT_GENERAL_SAVE'
export const ACCOUNT_GENERAL_AVATAR_URL = 'app/AccountGeneral/ACCOUNT_GENERAL_AVATAR_URL'
